import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);

    let path = location.pathname + location.search;
    let loc = window.location.origin + path;

    if (localStorage.getItem("gat") === "true") {
      ReactGA.set({ page: path });
      ReactGA.send({ hitType: "pageview", page: loc });
    }
  }, [location]);

  return null;
}

export default ScrollToTop;
