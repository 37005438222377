import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Flex, Box } from "@chakra-ui/react";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { CreditDataEnglish } from "./CreditsDataEnglish";
import { CreditDataHindi } from "./CreditsDataHindi";

export default function TOSModal({ openModal = false, closeModal }) {
  const [show, setShow] = useState(openModal);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setShow(openModal);
  }, [openModal]);

  const navigate = useNavigate();

  // const handleClose = () => closeModal();
  const handleClose = () => {
    closeModal();
    navigate(window.location.pathname);
  };
  const handleShow = () => setShow(true);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header style={{ justifyContent: "right" }}>
          <Modal.Title> {t("FOOTER.CREDITS")} </Modal.Title>
          <Flex
            align="center"
            justify="center"
            borderRadius="50%"
            width="30px"
            height="30px"
            backgroundColor="#008cf0"
            color="white"
            onClick={handleClose}
            cursor="pointer"
            position="sticky">
            <Box as={AiOutlineClose} color="white" />
          </Flex>
        </Modal.Header>
        <Modal.Body style={{ paddingRight: "0px" }}>
          <ModalContent creditData={i18n.language === "en" ? CreditDataEnglish : CreditDataHindi} />
        </Modal.Body>
        <Modal.Footer px={"20px"} bg="#f0f0f0">
          <div className="text-center w-100">
            <button className="mx-auto blueBtn " onClick={handleClose}>
              {" "}
              {t("FOOTER.MODAL_OK")}{" "}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const ModalContent = (data = { creditData: [] }) => {
  return (
    <div className="creditsModalContent">
      {data.creditData.map((org, orgIndex) => {
        return (
          <div key={orgIndex} className="creditsOrg">
            <OrgContent org={org} />
          </div>
        );
      })}
    </div>
  );
};

const OrgContent = (data = { org: {} }) => {
  const org = data.org;
  return (
    <ul className="creditsList">
      <li>
        <p className="name">{org.name}</p>
        <p className="role">{org.role}</p>
      </li>
      {org.people ? (
        <div className="creditsPeople">
          {org.people.map((person, personIndex) => {
            return (
              <li key={personIndex}>
                <p className="name">{person.name}</p>
                <p className="role">{person.role}</p>
                {person.org ? <OrgContent org={person.org} /> : ""}
              </li>
            );
          })}
        </div>
      ) : (
        ""
      )}
    </ul>
  );
};
