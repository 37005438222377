import API from "../api";
import { FETCH_CATEGORIES, FETCH_CATEGORIES_COMPLETE, FETCH_TOPIC, FETCH_TOPIC_COMPLETE } from "../constants/action";
import { formatCategories, formatTopics } from "../helper/formatTopics";

export async function doFetchCategories(dispatch) {
  dispatch({ type: FETCH_CATEGORIES });

  try {
    let response = await API.get("/categories?per_page=100");

    dispatch({ type: FETCH_CATEGORIES_COMPLETE, payload: { categories: formatCategories(response.data) } });
  } catch (error) {
    dispatch({ type: FETCH_CATEGORIES_COMPLETE, payload: { categories: [] } });
  }
}

export async function doFetchTopics(dispatch, category = "") {
  dispatch({ type: FETCH_TOPIC });

  try {
    let response = await API.get(`/topics?per_page=100`);

    // console.log("topics");
    // console.log(response.data);

    dispatch({ type: FETCH_TOPIC_COMPLETE, payload: { topics: formatTopics(response.data) } });
  } catch (error) {
    dispatch({ type: FETCH_CATEGORIES_COMPLETE, payload: { topics: [] } });
  }
}
