import React from "react";
import Banner from "../components/Banner";
import { useTranslation } from "react-i18next";
import API, { SetRateGame } from "../api";
import RatingBox from "../components/RatingBox";
import { Text } from "@chakra-ui/react";

export default function RateUs() {
  const { t, i18n } = useTranslation();
  const [qstns, setQstns] = React.useState([]);

  React.useEffect(() => {
    fetchQuestions();
  }, []);

  const fetchQuestions = async () => {
    try {
      let res = await API.get("/rate?per_page=100");

      let questions = [];

      if (res.data && res.data.length > 0) {
        questions = res.data.map((q) => {
          return { id: q.id, url: SetRateGame, ...q.acf };
        });
        setQstns(questions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  var ures = {};
  if (sessionStorage.getItem("ures") !== null) {
    ures = JSON.parse(sessionStorage.getItem("ures"));
  }

  return (
    <div class="categorypage supportpage">
      <Banner />

      <div className="container mb-4">
        <div className="content pb-0">
          <div className="rate-our-game">
            <div class="content__title text-blue mb-0">
              <Text fontSize="30px" fontFamily="heading" mt="8px">
                {t("SUPPORTPAGE.RATE_OUR_GAME")}{" "}
              </Text>
            </div>

            <Text fontSize="18px" fontFamily="body" mt="40px" mb="30px">
              {t("SUPPORTPAGE.LIKE_THE_GAME")}{" "}
            </Text>

            <RatingBox isCenter={true} is_main={true} id={1} url={SetRateGame} ures={ures} />

            <hr className="hr" />

            <div className="row">
              <div className="col-12">
                <p className="my-2 mt-4">{t("SUPPORTPAGE.FEEDBACK")}</p>
              </div>

              {qstns.map((q) => (
                <div className="col-12 col-md-6">
                  <RatingBox {...q} ures={ures} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
