import React from "react";
import Banner from "../components/Banner";
import NewsCard from "../components/NewsCard";
import { useTranslation } from "react-i18next";
import Loading from "../components/Loading";
import { useSelector, useDispatch } from "react-redux";
import { doFetchNews } from "../action/partners";

export default function News() {
  const { t, i18n } = useTranslation();

  const news = useSelector((state) => state.topics.news);
  const isNewsLoading = useSelector((state) => state.topics.isNewsLoading);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (news.length === 0 && !isNewsLoading) {
      doFetchNews(dispatch);
    }
  }, [isNewsLoading, news]);

  if (news.length === 0) {
    return <Loading />;
  }

  return (
    <div class="categorypage">
      <Banner />

      <div class="container">
        <div class="content pb-0">
          <div class="content__title mb-0" style={{ paddingTop: "36px", fontSize:"30px" }}>
            {t("COMMON.IN_THE_NEWS")}
          </div>
        </div>

        <div class="content">
          <div class="content__wrapper" style={{ paddingTop: "6px" }}>
            {news.map((nw) => (
              <NewsCard key={nw.id} {...nw} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
