import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import OpenInNewTab from "../utils/OpenInNewTab";
import { Box, Text, Flex, Image } from "@chakra-ui/react";
import { AiOutlineClose } from "react-icons/ai";
import { HDI_API, HEADERS } from "../api";

export default function RatingBox({
  isCenter = false,
  question,
  question_in_hindi,
  is_main = false,
  noBorder = false,
  id,
  url,
  ures,
}) {
  const [star, setStar] = React.useState(null);
  const [isSent, setIsSent] = React.useState(false);
  const [show, setShow] = useState(false);
  const { t, i18n } = useTranslation();

  const handleClose = () => setShow(false);

  const handleShow = () => {
    if (star === null) {
      return;
    }

    if (star < 3) {
      return;
    }

    if (!is_main) {
      return;
    }

    setShow(true);
  };

  const applyClass = (pos) => {
    if (ures[id] != undefined) {
      if (ures[id] == pos) {
        return "star-box--active";
      }
      return "star-box--disabled";
    }

    if (star === null) {
      return "";
    }

    if (star === pos) {
      return "star-box--active";
    }

    return "star-box--disabled";
  };

  const selectStar = (pos) => {
    setStar(pos);
  };

  const handleSend = () => {
    if (star === null) {
      return;
    }

    if (is_main) {
      handleShow();
    }
    setIsSent(true);
    sendData();
  };

  const sendData = () => {
    let pstdt = new FormData();
    pstdt.append("optId", star);
    HDI_API.post(url + id, pstdt, { headers: HEADERS })
      .then((res) => {
        ures[id] = star;
        sessionStorage.setItem("ures", JSON.stringify(ures));
      })
      .catch((err) => console.log(err));
  };

  const noBorderStyles = {
    border: "0px solid transparent",
    boxShadow: "none",
    padding: "24px",
  };

  return (
    <>
      <div className="rating">
        <div
          className={` rating__box ${isSent || ures[id] != undefined ? "rating__box--sent" : ""}`}
          style={noBorder ? noBorderStyles : { padding: "24px" }}>
          <p className={`${isCenter ? "text-center" : "text-left px-2"} my-2`}>
            {i18n.language === "en" ? question : question_in_hindi}
          </p>

          <div className="rating__stars">
            <div onClick={() => selectStar(1)} className={`star-box ${applyClass(1)}`}>
              <i class="fa fa-circle" aria-hidden="true"></i>
              <i class="fa fa-circle-o" aria-hidden="true"></i>
              <Image maxHeight={is_main ? "unset" : "50px"} srcSet="/assets/img/stars/1.png" className="img-fluid" />
            </div>

            <div onClick={() => selectStar(2)} className={`star-box ${applyClass(2)}`}>
              <i class="fa fa-circle" aria-hidden="true"></i>
              <i class="fa fa-circle-o" aria-hidden="true"></i>
              <Image maxHeight={is_main ? "unset" : "50px"} srcSet="/assets/img/stars/2.png" className="img-fluid" />
            </div>

            <div onClick={() => selectStar(3)} className={`star-box ${applyClass(3)}`}>
              <i class="fa fa-circle" aria-hidden="true"></i>
              <i class="fa fa-circle-o" aria-hidden="true"></i>
              <Image maxHeight={is_main ? "unset" : "50px"} srcSet="/assets/img/stars/3.png" className="img-fluid" />
            </div>

            <div onClick={() => selectStar(4)} className={`star-box ${applyClass(4)}`}>
              <i class="fa fa-circle" aria-hidden="true"></i>
              <i class="fa fa-circle-o" aria-hidden="true"></i>
              <Image maxHeight={is_main ? "unset" : "50px"} srcSet="/assets/img/stars/4.png" className="img-fluid" />
            </div>

            <div onClick={() => selectStar(5)} className={`star-box ${applyClass(5)}`}>
              <i class="fa fa-circle" aria-hidden="true"></i>
              <i class="fa fa-circle-o" aria-hidden="true"></i>
              <Image maxHeight={is_main ? "unset" : "50px"} srcSet="/assets/img/stars/5.png" className="img-fluid" />
            </div>
          </div>

          <Flex className="text-center" style={{ minHeight: "80px" }} align="center" justify="center">
            {isSent || ures[id] != undefined ? (
              <Text textAlign="center" my={2} lineHeight="43px" fontFamily="heading" fontSize="23px" color="#008CF0">
                {t("SUPPORTPAGE.THANK_YOU_FOR_SUBMITTING")}
              </Text>
            ) : (
              <button
                className={`send-btn ${star != null ? "send-btn--active" : ""}`}
                onClick={handleSend}
                style={{
                  minWidth: "124px",
                  marginRight: "0px",
                  fontSize: "26px",
                  paddingTop: "8px",
                  paddingBottom: "8px",
                }}>
                {t("SUPPORTPAGE.SEND")}
              </button>
            )}
          </Flex>
        </div>
      </div>

      {is_main ? (
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <Modal.Header style={{ justifyContent: "right" }}>
            <Modal.Title> {t("SUPPORTPAGE.THANK_YOU")} ! </Modal.Title>
            <Flex
              align="center"
              justify="center"
              borderRadius="50%"
              width="30px"
              height="30px"
              backgroundColor="#008cf0"
              color="white"
              onClick={handleClose}
              cursor="pointer"
              position="sticky">
              <Box as={AiOutlineClose} color="white" />
            </Flex>
          </Modal.Header>
          <Modal.Body>
            <div className="rating__modal-body">
              {star != null ? (
                <img
                  style={{ height: "100px", width: "auto" }}
                  srcSet={`/assets/img/stars/${star}.png`}
                  className="img-fluid"
                />
              ) : (
                <img
                  style={{ height: "100px", width: "auto" }}
                  srcSet={"/assets/img/stars/4.png"}
                  className="img-fluid"
                />
              )}

              <p>{t("SUPPORTPAGE.REVIEW")}</p>

              <button
                className={`review-btn ${star != null ? "send-btn--active" : ""}`}
                onClick={() => OpenInNewTab(t("COMMON.LINKS.PLAYSTORE"))}>
                {t("SUPPORTPAGE.GO_TO_PLAYSTORE")}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        ""
      )}
    </>
  );
}
