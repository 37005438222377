import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Flex, Box } from "@chakra-ui/react";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

export default function TOSModal({ openModal = false, closeModal }) {
  const [show, setShow] = useState(openModal);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setShow(openModal);
  }, [openModal]);

  const navigate = useNavigate();

  // const handleClose = () => closeModal();
  const handleClose = () => {
    closeModal();
    navigate(window.location.pathname);
  };
  const handleShow = () => setShow(true);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header style={{ justifyContent: "right" }}>
          <Modal.Title> {t("FOOTER.TOS")} </Modal.Title>
          <Flex
            align="center"
            justify="center"
            borderRadius="50%"
            width="30px"
            height="30px"
            backgroundColor="#008cf0"
            color="white"
            onClick={handleClose}
            cursor="pointer"
            position="sticky">
            <Box as={AiOutlineClose} color="white" />
          </Flex>
        </Modal.Header>
        <Modal.Body style={{ paddingRight: "0px" }}>
          {i18n.language === "en" ? <ModalContentEnglish /> : <ModalContentHindi />}
        </Modal.Body>
        <Modal.Footer px={"20px"} bg="#f0f0f0">
          <div className="text-center w-100">
            <button className="mx-auto blueBtn " onClick={handleClose}>
              {" "}
              {t("FOOTER.MODAL_OK")}{" "}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const ModalContentEnglish = () => {
  return (
    <div className="privacyModalContent">
      <div style={{ textAlign: "justify", paddingRight: "1.25rem" }}>
        <p>
          <i>
            Game of Choice, Not Chance<sup>TM</sup>
          </i>{" "}
          is a branded digital platform using play to empower the choices and voices of young people. The first product
          offered by the said platform is ‘Go Nisha Go”, a game app and its connected microsite which are owned by
          Howard Delafield International (‘HDI’, we, our or us), a behavioural insights consultancy.
        </p>

        <p>
          These terms and conditions of use ("Terms of Service") apply to all users of the Game of Choice, Not Chance
          platform, Go Nisha Go game app, and/or the microsite (
          <a className="emailhover" href="https://www.gonishago.com/" target="_blank">
            https://www.gonishago.com/
          </a>
          ).
        </p>

        <p>
          The Terms of Service, together with our Privacy Policy and the consents and disclaimers appearing within the
          game at various levels, constitute the entire legal agreement between you, the user, and HDI in relation to
          the use of the Go Nisha Go game app and Go Nisha Go microsite (
          <a className="emailhover" href="https://www.gonishago.com/" target="_blank">
            https://www.gonishago.com/
          </a>
          ), and supersedes all prior agreements and arrangements in relation to your use of the site. The Terms of
          Service provides the terms and conditions under which you shall access and use the Go Nisha Go game app and
          the microsite (
          <a className="emailhover" href="https://www.gonishago.com/" target="_blank">
            https://www.gonishago.com/
          </a>
          ).
        </p>

        <p>
          Please read the Terms of Service carefully before downloading the Go Nisha Go game app. By downloading,
          accessing, or using the Go Nisha Go game app, you agree to be bound by these Terms of Service, the Privacy
          Policy, and Disclaimers associated with the Go Nisha Go game app.
        </p>

        <p style={{ fontWeight: 700, paddingTop: "20px", paddingTop: "20px" }}>USE OF THE SITE</p>

        <ol style={{ paddingLeft: "2rem" }}>
          <li>
            The Go Nisha Go game app and the information available within the game or the microsite (
            <a className="emailhover" href="https://www.gonishago.com/" target="_blank">
              https://www.gonishago.com/
            </a>
            ) is only intended for educational purposes and to facilitate an understanding of issues relating to
            adolescent health and well-being. The technical content in the game has been developed by health educators
            and academicians, technical specialists in the area of sexual and reproductive health and family planning,
            and public health professionals from adolescent, youth, and gender programs, and is consistent with the
            international technical guidance on sexuality education (
            <a
              className="emailhover"
              href="https://www.unfpa.org/sites/default/files/pub-pdf/ITGSE.pdf"
              target="_blank">
              https://www.unfpa.org/sites/default/files/pub-pdf/ITGSE.pdf
            </a>
            ).
          </li>

          <li>
            The information covered through the medium of the Go Nisha Go game app is consistent with the objectives and
            principles of the “School Health Program” launched under the “Ayushman Bharat” program of the Government of
            India, with specific reference to the age appropriate topics recommended for middle and high school. The
            School Health Program initiative is aimed to strengthen the existing programs: “Rashtriya Bal Swasthya
            Karyakram” (RBSK) and “Rashtriya Kishor Swasthya Karyakram” (RKSK) by strengthening the preventive and
            promotive aspects of health in environment of schools, in line with the overall approach of “Ayushman
            Bharat”, wherein activities have been designed to empower students to take charge of their health and make
            appropriate choices for practicing healthy behaviours. (For more information, please see the &nbsp;{" "}
            <a
              className="emailhover"
              href="https://nhm.gov.in/New_Updates_2018/NHM_Components/RMNCHA/AH/guidelines/Operational_guidelines_on_School_Health_Programme_under_Ayushman_Bharat.pdf"
              target="_blank">
              Operational Guidelines on School Health Programme under Ayushman Bharat
            </a>
            ).
          </li>

          <li>
            These topics include personal hygiene (including menstrual health and management), relationships,
            gender-based violence (GBV), early marriage and sexual and reproductive health. The game has been developed
            based on insights from formative research with Indian girls in the 15-19 age group and has been designed and
            tested with a cohort of adolescent girls in this age group over a period of two years, using evidence-based
            research, human centred design and rapid prototyping for testing the game mechanics and usability.
          </li>

          <li>
            By agreeing to the Terms of Service, you are declaring that you are 15 years or above and understand the
            terms and conditions. By downloading the Go Nisha Go game app, you confirm that you are aged 15 years or
            above. If you are in the age group of 15-18 years, you agree to obtain your parent/guardian's consent to
            download the Game. In the game, Levels 3, 4 and 5 deal with content related to GBV and consent, fertility
            awareness and contraception, and delaying early marriage, with the purpose of improving knowledge and
            awareness on sexual and reproductive health for adolescents and youth. Parental guidance is essential for
            users who are under 18 years. If you have any questions, you may contact us at &nbsp;{" "}
            <a className="emailhover" href="mailto:support@gameofchoicenotchance.com">
              support@gameofchoicenotchance.com
            </a>
          </li>

          <li>
            We reserve the right to make changes to the Terms of Service at any time. Any changes will be effective
            immediately upon posting of the revised Terms of Service. By continuing to download or access the Go Nisha
            Go game app or the microsite (
            <a className="emailhover" href="https://www.gonishago.com/" target="_blank">
              https://www.gonishago.com/
            </a>
            ) after these Terms and Conditions have changed, you confirm that you have read, understood and agreed to be
            bound by any revised Terms of Service. If you do not agree to these Terms of Service, as may be updated,
            please do not download, access or use the Go Nisha Go game app or the microsite (
            <a className="emailhover" href="https://www.gonishago.com/" target="_blank">
              https://www.gonishago.com/
            </a>
            ).
          </li>

          <li>
            The information available through the Go Nisha Go game app is not intended to be a substitute for
            professional medical advice, diagnosis, or treatment. Advice of a qualified healthcare professional must be
            sought in case of any questions regarding a medical condition or treatment.
          </li>
        </ol>

        <p style={{ fontWeight: 700, paddingTop: "20px" }}>MATERIAL ON THE SITE</p>

        <ol style={{ paddingLeft: "2rem" }} start={7}>
          <li>
            All text, graphics, audio, video or image files, content, software, applications and information designed
            and created for the Go Nisha Go game app or the Go Nisha Go microsite, (
            <a className="emailhover" href="https://www.gonishago.com/" target="_blank">
              https://www.gonishago.com/
            </a>
            ) and all copyright, trademarks and other intellectual property rights in them (together, the "Material")
            are the property of HDI.
          </li>
        </ol>

        <p style={{ fontWeight: 700, paddingTop: "20px" }}>COPYRIGHT AND INTELLECTUAL PROPERTY RIGHTS</p>

        <ol style={{ paddingLeft: "2rem" }} start={8}>
          <li>
            <i>
              Game of Choice, Not Chance<sup>TM</sup>
            </i>{" "}
            and associated brand name of the game “Go Nisha Go” and microsite (
            <a className="emailhover" href="https://www.gonishago.com/" target="_blank">
              https://www.gonishago.com/
            </a>
            ), logo and colour scheme are trademarks of HDI. All Material, Trademarks, Copyrights, and other
            ‘Intellectual Property’ associated with the Go Nisha Go game app are the sole property of and belong
            exclusively to HDI. You are prohibited from using the Go Nisha Go game app’s name, logo, colour scheme or
            other trademarks.
          </li>

          <li>
            By allowing use of the Go Nisha Go game app, no license to use HDI’s Intellectual Property is being
            provided. HDI expressly and exclusively reserves all the Intellectual Property rights in all text, programs,
            products, processes, technology, content, software and other materials, which appear on this Go Nisha Go
            game app. Consequently, the materials on the Go Nisha Go game app shall not be copied, reproduced,
            duplicated, republished, posted, transmitted, distributed or modified in whole or in part or in any other
            form whatsoever, without prior written permission from HDI.
          </li>

          <li>
            No right, title or interest in any downloaded materials is transferred to you as a result of any such
            downloading.
          </li>

          <li>
            No content may be reproduced or be used in any manner adversely affecting any right or interest of HDI.
          </li>

          <li>
            No copy or copies must be made for sale, gain, profit or reward of any kind, or any other “Commercial
            Purposes” without express permission from HDI and a signed statement of collaboration with HDI. Commercial
            purposes include purposes to offer to provide a business opportunity and/or to advertise or promote any
            goods, services or fixed properties. HDI must be acknowledged as the copyright owner of such content, and
            acknowledgement must also be given to the Go Nisha Go game app and the Go Nisha Go microsite as the source
            of such content, after seeking permissions for reproduction from HDI and having a signed statement of
            collaboration with HDI.
          </li>

          <li>
            Where third-party copyright is involved in content found on the Go Nisha Go game app or Go Nisha Go
            microsite, permission for reproduction, distribution or otherwise use of any such content must be obtained
            from the copyright owner(s) concerned.
          </li>

          <li>
            You agree to abide by intellectual property laws and all of the terms and conditions mentioned in the Terms
            of Service, specifically this clause. If you have any questions and/or comments regarding these matters,
            please contact &nbsp;
            <a className="emailhover" href="mailto:support@gameofchoicenotchance.com">
              support@gameofchoicenotchance.com
            </a>
            .
          </li>
        </ol>

        <p style={{ fontWeight: 700, paddingTop: "20px" }}>THIRD-PARTY LINKS</p>

        <ol style={{ paddingLeft: "2rem" }} start={15}>
          <li>
            The Go Nisha Go game app includes links to third-party web sites (‘links’) to provide you with information
            and direct access. By accessing the links available on the Go Nisha Go game app, you accept that any links
            to third-party websites available on the Go Nisha Go game app are provided in good faith and “as is” without
            warranty of any kind, either expressed or implied and access to such links and/or information or products
            available on these links is at your own risk.
          </li>

          <li>
            The inclusion of these links is not to be taken as an advertisement, endorsement, recommendation or
            favouring by HDI of the third-party sites or any products or services promoted, offered or sold on the
            third-party sites.
          </li>

          <li>
            You agree that you will not hold HDI responsible for the content, products, services or any advertising on
            the links, or for any issues or consequences that may arise from accessing the links and/or using the
            content on these links. No guarantee of availability or reliability of either use or accuracy is given by
            HDI or may be assumed. HDI cannot be held responsible or liable for any damage (direct, indirect,
            consequential or other) or loss resulting from accessing or using the information or products available on
            these links.
          </li>

          <li>
            The sites accessed through these links do not follow our Privacy Policy or Terms of Service, and we
            recommend that you read their terms and conditions, privacy policies, and disclaimers carefully.
          </li>
        </ol>

        <p style={{ fontWeight: 700, paddingTop: "20px" }}>PRIVACY</p>

        <ol style={{ paddingLeft: "2rem" }} start={19}>
          <li>
            HDI is committed to safeguarding your privacy. The terms regulating the handling of any information
            submitted by you in connection with the Go Nisha Go game app or Go Nisha Go microsite are described in our
            Privacy Policy.
          </li>
        </ol>

        <p style={{ fontWeight: 700, paddingTop: "20px" }}>LIMITATION OF LIABILITY</p>

        <ol style={{ paddingLeft: "2rem" }} start={20}>
          <li>
            The Go Nisha Go game app has been created with gender and adolescent health experts, as well as co-designed
            and tested with the target audience to ensure age-appropriate content. However, HDI is not liable for the
            completeness and current standing of the content made available.
          </li>

          <li>
            To the maximum extent permitted by law, you shall use the Go Nisha Go game app and the Go Nisha Go microsite
            at your own risk, and HDI will not be liable to you for any costs or losses you incur through your use of
            the same.
          </li>

          <li>
            A failure or delay by HDI to enforce its rights under these Terms of Service is not a waiver and HDI remains
            entitled to enforce its rights.
          </li>
        </ol>

        <p style={{ fontWeight: 700, paddingTop: "20px" }}>MISCELLANEOUS</p>

        <ol style={{ paddingLeft: "2rem" }} start={23}>
          <li>
            The Terms of Service and any disputes in relation to them are governed by Indian law. Both you and HDI agree
            to submit to the non-exclusive jurisdiction of the Indian courts.
          </li>

          <li>
            If any part or provision of these Terms of Service is or becomes illegal, invalid or unenforceable, this
            shall not impair the legality, validity and enforceability of all other provisions and parts of the Terms of
            Service.
          </li>
        </ol>

        <p style={{ fontWeight: 700, paddingTop: "20px" }}>
          Last modified on <u>31 May 2022</u>
        </p>

        <br />
        <br />
        <br />
      </div>
    </div>
  );
};

const ModalContentHindi = () => {
  return (
    <div className="privacyModalContent">
      <div style={{ textAlign: "justify", paddingRight: "1.25rem" }}>
        <p>
          <i>
            Game of Choice, Not Chance<sup>TM</sup>
          </i>{" "}
          एक ब्रांडेड डिज़िटल प्लेटफ़ॉर्म है जो नाटकों का उपयोग करते हुए, युवाओं के विकल्पों और उनकी आवाज़ को सशक्त बनाता
          है। इस प्लेटफ़ॉर्म द्वारा प्रस्तुत पहला उत्पाद ‘Go Nisha Go’ है, जो कि एक गेम ऐप है और इसकी संबंधित माइक्रोसाइट
          है, जिसका स्वामित्व Howard Delafield International ('HDI', हम, हमारे या हमें) के पास है, जो कि एक व्यवहारगत
          अंतर्दृष्टि परामर्श (बिहेविरल इनसाइट्स कंसल्टैंसी) कंपनी है।
        </p>

        <p>
          उपयोग के ये नियम एवं शर्तें ("सेवा की शर्तें") Game of Choice, Not Chance प्लेटफ़ॉर्म, Go Nisha Go गेम ऐप,
          और/या माइक्रोसाइट (
          <a className="emailhover" href="https://www.gonishago.com/" target="_blank">
            https://www.gonishago.com/
          </a>
          ) के सभी उपयोगकर्ताओं पर लागू हैं।
        </p>

        <p>
          हमारी गोपनीयता नीति, और गेम के अंदर विभिन्न स्तरों पर प्रदर्शित सहमतियों और अस्वीकरणों के साथ सेवा की शर्तें,
          Go Nisha Go गेम ऐप और Go Nisha Go माइक्रोसाइट (
          <a className="emailhover" href="https://www.gonishago.com/" target="_blank">
            https://www.gonishago.com/
          </a>
          ) के उपयोग के संदर्भ में आप, उपयोगकर्ता और HDI के बीच संपूर्ण कानूनी अनुबंध का गठन करती हैं और साइट के आप
          द्वारा उपयोग के संदर्भ में समस्त पूर्व अनुबंधों और व्यवस्थाओं को प्रतिस्थापित करती हैं। सेवा की शर्तों में वे
          नियम एवं शर्तें प्रदान किए गए हैं जिनके अंतर्गत आप Go Nisha Go गेम ऐप और माइक्रोसाइट (
          <a className="emailhover" href="https://www.gonishago.com/" target="_blank">
            https://www.gonishago.com/
          </a>
          ) तक अभिगमन और इनका उपयोग करेंगे।
        </p>

        <p>
          कृपया Go Nisha Go गेम ऐप डाउनलोड करने से पहले सेवा की शर्तों को सावधानीपूर्वक पढ़ें। Go Nisha Go गेम ऐप डाउनलोड
          करके, अभिगमन या उपयोग करके आप इन सेवा की शर्तों, गोपनीयता नीति, और Go Nisha Go गेम ऐप से संबंधित अस्वीकरणों का
          पालन करने के लिए सहमति प्रदान करेंगे।
        </p>

        <p style={{ fontWeight: 700, paddingTop: "20px", paddingTop: "20px" }}>साइट का उपयोग करना</p>

        <ol style={{ paddingLeft: "2rem" }}>
          <li>
            Go Nisha Go गेम ऐप, और गेम या माइक्रोसाइट (
            <a className="emailhover" href="https://www.gonishago.com/" target="_blank">
              https://www.gonishago.com/
            </a>
            ) में उपलब्ध जानकारी केवल शैक्षिक प्रयोजन से है और किशोरों के स्वास्थ्य और कल्याण से संबंधित समस्याओं के
            बारे में समझ सुगम बनाने के लिए है। गेम की तकनीकी विषयवस्तु को स्वास्थ्य शिक्षकों और अकादमिक व्यक्तियों, यौन
            और प्रजनन स्वास्थ्य और परिवार नियोजन के क्षेत्र से संबंधित तकनीकी विशेषज्ञों और किशोरों, युवाओं और जेंडर
            आधारित कार्यक्रमों से जुड़े सार्वजनिक स्वास्थ्य पेशेवरों द्वारा विकसित की गई है, और यौन शिक्षा के बारे में
            अंतर्राष्ट्रीय तकनीकी दिशानिर्देशों के अनुरूप है (
            <a
              className="emailhover"
              href="https://www.unfpa.org/sites/default/files/pub-pdf/ITGSE.pdf"
              target="_blank">
              https://www.unfpa.org/sites/default/files/pub-pdf/ITGSE.pdf
            </a>
            )।
          </li>

          <li>
            Go Nisha Go गेम ऐप के माध्यम से कवर की गई जानकारी, माध्यमिक और हाईस्कूल के लिए अनुशंसित आयु के अनुरूप
            उपयुक्त विषयों के विशिष्ट संदर्भ सहित, भारत सरकार के “आयुष्मान भारत” कार्यक्रम के अंतर्गत प्रारंभ “स्कूल
            स्वास्थ्य कार्यक्रम” के उद्देश्यों और सिद्धांतों के अनुरूप है। “आयुष्मान भारत” की समग्र विधि के अनुरूप,
            स्कूलों के परिवेश में निरोधात्मक और संवर्धन स्वास्थ्य पहलुओं को सुदृढ़ बनाते हुए मौजूदा कार्यक्रमों
            “राष्ट्रीय बाल स्वास्थ्य कार्यक्रम” (RBSK) और “राष्ट्रीय किशोर स्वास्थ्य कार्यक्रम” (RKSK) को सशक्त बनाना
            स्कूल स्वास्थ्य कार्यक्रम नामक पहल का ध्येय है, जबकि गतिविधियों को इस प्रकार डिज़ाइन किया गया है कि छात्रों
            को उनके स्वास्थ्य की जिम्मेदारी संभालने, तथा स्वास्थ्यप्रद व्यवहार अपनाने के लिए उपयुक्त विकल्प चुनने की
            दिशा में उनको सशक्त बनाया जाए। (अधिक जानकारी के लिए, कृपया &nbsp;{" "}
            <a
              className="emailhover"
              href="https://nhm.gov.in/New_Updates_2018/NHM_Components/RMNCHA/AH/guidelines/Operational_guidelines_on_School_Health_Programme_under_Ayushman_Bharat.pdf"
              target="_blank">
              आयुष्मान भारत के अंतर्गत स्कूल स्वास्थ्य कार्यक्रम के प्रचालन दिशानिर्देश
            </a>
            &nbsp; देखें।)
          </li>

          <li>
            इन विषयों में निजी स्वच्छता (मासिक धर्म स्वास्थ्य और प्रबंधन सहित), रिश्ते, जेंडर आधारित हिंसा (GBV), बाल
            विवाह और यौन और प्रजनन स्वास्थ्य सम्मिलित हैं। इस गेम को, 15-19 आयु वर्ग वाली भारतीय बालिकाओं से संबंधित
            रचनात्मक अनुसंधान से प्राप्त अंतर्दृष्टि के आधार पर विकसित किया गया है और साक्ष्य-आधारित अनुसंधान, मानव
            केंद्रित डिज़ाइन, और गेम मैकेनिक्स और उपयोगिता के परीक्षण के लिए रैपिड प्रोटोटाइपिंग का उपयोग करते हुए, दो
            वर्ष की अवधि में इसे डिज़ाइन किया गया है और इस आयु वर्ग वाली किशोर बालिकाओं के एक समूह पर परीक्षण किया गया
            है।
          </li>

          <li>
            सेवा की शर्तों के प्रति सहमति देकर आप यह घोषणा करते हैं कि आप की आयु 15 वर्ष या अधिक है और आप नियम और शर्तों
            को समझते हैं। Go Nisha Go गेम ऐप डाउनलोड करके, आप इसकी पुष्टि करते हैं कि आपकी आयु 15 वर्ष या उससे अधिक है।
            यदि आप 15-18 वर्ष के आयु समूह में हैं, तो आप गेम को डाउनलोड करने के लिए अपने माता-पिता/अभिभावक की सहमति
            प्राप्त करने के लिए सहमति देंगे। गेम में लेवल 3, 4 और 5 GBV और सहमति, प्रजनन जागरूकता और गर्भनिरोध, और बाल
            विवाह टालने से संबंधित विषयवस्तु के बारे में हैं जो किशोरों और युवाओं के यौन और प्रजनन संबंधी ज्ञान और
            जागरूकता बढ़ाने के लिए हैं। 18 वर्ष से कम आयु के उपयोगकर्ताओं के लिए माता-पिता का मार्गदर्शन आवश्यक है। यदि
            आपका कोई प्रश्न हो तो आप हमसे &nbsp;{" "}
            <a className="emailhover" href="mailto:support@gameofchoicenotchance.com">
              support@gameofchoicenotchance.com
            </a>
            &nbsp; पर संपर्क कर सकते हैं
          </li>

          <li>
            इन सेवा की शर्तों में किसी भी समय परिवर्तन करने का अधिकार हमारे पास सुरक्षित है। कोई भी परिवर्तन, पुनरीक्षित
            सेवा की शर्तें पोस्ट करने के तुरंत पश्चात से प्रभावी होगा। इन नियमों और शर्तों में परिवर्तन के बाद भी Go
            Nisha Go गेम ऐप या माइक्रोसाइट (
            <a className="emailhover" href="https://www.gonishago.com/" target="_blank">
              https://www.gonishago.com/
            </a>
            ) को डाउनलोड या अभिगमन करना जारी रखने पर आप पुष्टि करेंगे कि आपने किन्हीं संशोधित सेवा शर्तों को पढ़, समझ
            लिया है और उनका पालन करने के लिए सहमत हैं। यदि आप इन सेवा की शर्तों से सहमत नहीं हैं, जैसा कि उन्हें अपडेट
            किया गया हो सकता है, तो कृपया Go Nisha Go गेम ऐप या माइक्रोसाइट (
            <a className="emailhover" href="https://www.gonishago.com/" target="_blank">
              https://www.gonishago.com/
            </a>
            ) को डाउनलोड, अभिगमन या उपयोग न करें।
          </li>

          <li>
            Go Nisha Go गेम ऐप के माध्यम से उपलब्ध जानकारी, पेशेवर चिकित्सा सलाह, निदान या उपचार का विकल्प नहीं है।
            चिकित्सा समस्या या उपचार के संबंध में कोई प्रश्न होने पर किसी सुयोग्य स्वास्थ्यसेवा पेशेवर से परामर्श अवश्य
            लें।
          </li>
        </ol>

        <p style={{ fontWeight: 700, paddingTop: "20px" }}>साइट पर सामग्री</p>

        <ol style={{ paddingLeft: "2rem" }} start={7}>
          <li>
            Go Nisha Go गेम ऐप या Go Nisha Go माइक्रोसाइट, (
            <a className="emailhover" href="https://www.gonishago.com/" target="_blank">
              https://www.gonishago.com/
            </a>
            )&nbsp; पर डिज़ाइन और निर्मित समस्त टेक्स्ट, ग्राफिक्स, ऑडियो, वीडियो या इमेज़ फ़ाइलें, सामग्री, सॉफ़्टवेयर,
            एप्लिकेशन और जानकारी और समस्त कॉपीराइट, ट्रेडमार्क और उनमें अन्य बौद्धिक संपदा अधिकार (सामूहिक रूप से
            "सामग्री") HDI की संपत्ति हैं।
          </li>
        </ol>

        <p style={{ fontWeight: 700, paddingTop: "20px" }}>कॉपीराइट और बौद्धिक संपदा से जुड़े अधिकार</p>

        <ol style={{ paddingLeft: "2rem" }} start={8}>
          <li>
            <i>
              Game of Choice, Not Chance<sup>TM</sup>
            </i>{" "}
            और गेम का सम्बद्ध ब्रांड नाम “Go Nisha Go” और माइक्रोसाइट (
            <a className="emailhover" href="https://www.gonishago.com/" target="_blank">
              https://www.gonishago.com/
            </a>
            ), लोगो और कलर स्कीम, HDI के ट्रेडमार्क हैं। Go Nisha Go गेम ऐप से संबंधित सभी सामग्रियां, ट्रेडमार्क,
            कॉपीराइट और अन्य ‘बौद्धिक संपदाएं’, पूर्णतया तथा विशिष्ट रूप से केवल HDI की संपत्ति व उनसे संबंधित हैं। आपको
            Go Nisha Go गेम ऐप का नाम, लोगो, कलर स्कीम या अन्य ट्रेडमार्क उपयोग करने से निषिद्ध किया जाता है।
          </li>

          <li>
            Go Nisha Go गेम ऐप का उपयोग करने की अनुमति देकर HDI की बौद्धिक संपदा का उपयोग करने का अधिकार नहीं दिया गया
            है। HDI इस Go Nisha Go गेम ऐप पर प्रदर्शित समस्त टेक्स्ट, प्रोग्रामों, उत्पादों, प्रक्रियाओं, तकनीक,
            विषयवस्तु, सॉफ्टवेयर और अन्य सामग्रियों में समस्त बौद्धिक संपदा अधिकारों को स्पष्ट और विशिष्ट रूप से
            सुरक्षित रखता है। परिणामस्वरूप Go Nisha Go गेम ऐप पर सामग्रियों को HDI से पूर्व लिखित अनुमति के बिना कॉपी,
            पुनरुत्पादित, डुप्लिकेट, पुनर्प्रकाशित, पोस्ट, ट्रांसमिट, वितरित या संशोधित नहीं किया जा सकता है।
          </li>

          <li>
            ऐसी किसी डाउनलोडिंग के परिणामस्वरूप डाउनलोड की गई किसी सामग्री में आपको कोई अधिकार, शीर्षक या हित हस्तांतरित
            नहीं किया गया है।
          </li>

          <li>
            किसी ऐसी सामग्री का पुनरुत्पादन या उपयोग नहीं किया जा सकता है जो HDI के किसी अधिकार या हित को प्रतिकूल रूप
            से प्रभावित करती हो।
          </li>

          <li>
            HDI से स्पष्ट अनुमति और HDI के साथ हस्ताक्षरित सहयोग अनुबंध के बिना, किसी प्रकार की बिक्री, प्राप्ति,
            मुनाफ़ा, या पुरस्कार या किसी अन्य “वाणिज्यिक प्रयोजन” के लिए कोई प्रतिलिपि या प्रतियां नहीं बनाई जानी चाहिए।
            व्यावसायिक अवसर प्रदान करने और/या किसी माल, सेवा या अचल संपत्तियों का विज्ञापन या प्रचार करने के प्रयोजन भी
            वाणिज्यिक प्रयोजनों में सम्मिलित हैं। HDI को ऐसी विषयवस्तु के कॉपीराइट धारक के रूप में उल्लेखित किया जाना
            चाहिए, और पुनरुत्पादन के लिए HDI से अनुमति लेने और HDI के साथ अनुबंध हस्ताक्षरित होने के बाद, उस सामग्री के
            स्रोत के रूप में Go Nisha Go गेम ऐप और Go Nisha Go माइक्रोसाइट को अभिस्वीकृति भी दी जानी चाहिए।
          </li>

          <li>
            Go Nisha Go गेम ऐप या Go Nisha Go माइक्रोसाइट पर उपलब्ध विषयवस्तु में तीसरी पार्टी के कॉपीराइट शामिल होने
            पर, ऐसी किसी विषयवस्तु के पुनरुत्पादन, वितरण या अन्यथा उपयोग की अनुमति, संबंधित कॉपीराइट धारक से प्राप्त की
            जानी चाहिए।
          </li>

          <li>
            आप बौद्धिक संपदा कानूनों, और सेवा की शर्तों, विशेष रूप से इस खंड में उल्लिखित समस्त नियमों और शर्तों का पालन
            करने के लिए सहमत हैं। यदि इन मामलों के संबंध में आपका कोई प्रश्न और/या टिप्पणी है, तो कृपया &nbsp;
            <a className="emailhover" href="mailto:support@gameofchoicenotchance.com">
              support@gameofchoicenotchance.com
            </a>
            &nbsp; से संपर्क करें।
          </li>
        </ol>

        <p style={{ fontWeight: 700, paddingTop: "20px" }}>तीसरी पार्टी के लिंक</p>

        <ol style={{ paddingLeft: "2rem" }} start={15}>
          <li>
            आपको जानकारी और सीधी पहुंच प्रदान करने के लिए Go Nisha Go गेम ऐप में तीसरी पार्टी के वेबसाइट्स (‘लिंक’) के
            लिंक शामिल हैं। Go Nisha Go गेम ऐप पर उपलब्ध लिंकों पर अभिगमन करके आप यह स्वीकृत करते हैं कि Go Nisha Go गेम
            ऐप पर तीसरी पार्टी की वेबसाइटों के कोई उपलब्ध लिंक, किसी प्रकार की स्पष्ट या निहित वारंटी के बिना
            सद्भावपूर्वक और “जैसा है” के रूप में प्रदान किए गए हैं और ऐसे लिंकों और/या इन लिंकों पर उपलब्ध जानकारी या
            उत्पादों तक पहुंच आपके अपने जोखिम पर होगी।
          </li>

          <li>
            इन लिंकों को शामिल करना, HDI द्वारा तीसरी पार्टी की साइटों का, या तीसरी पार्टी की साइटों द्वारा प्रचारित,
            प्रस्तावित या बेचे जाने वाले किन्हीं उत्पादों या सेवाओं का विज्ञापन, पृष्ठांकन, अनुशंसा या पक्ष लेना नहीं
            माना जाना चाहिए।
          </li>

          <li>
            आप सहमति प्रदान करते हैं कि आप लिंक पर सामग्री, उत्पादों, सेवाओं या किसी भी विज्ञापन के लिए या लिंक तक
            अभिगमन करने और/या इन लिंक पर सामग्री का उपयोग करने के कारण उत्पन्न किन्हीं समस्याओं या परिणामों के लिए HDI
            को उत्तरदायी नहीं ठहराएंगे। उपयोग या सटीकता की उपलब्धता या विश्वसनीयता के लिए HDI द्वारा कोई गारंटी नहीं दी
            जाती है या ऐसा माना नहीं जाना चाहिए। इन लिंकों पर उपलब्ध जानकारी या उत्पादों तक अभिगमन करने, या उपयोग करने
            से होने वाली किसी क्षति (प्रत्यक्ष, अप्रत्यक्ष, परिणामी या अन्य) या हानि के लिए HDI को उत्तरदायी या भागी
            नहीं ठहराया जा सकता है।
          </li>

          <li>
            इन लिंकों के माध्यम से एक्सेस की गई साइटें हमारी गोपनीयता नीति या सेवा की शर्तों का पालन नहीं करतीं, और हम
            अनुशंसा करते हैं कि आप उनके नियमों और शर्तों, गोपनीयता नीतियों और अस्वीकरणों को ध्यान से पढ़ें।
          </li>
        </ol>

        <p style={{ fontWeight: 700, paddingTop: "20px" }}>गोपनीयता</p>

        <ol style={{ paddingLeft: "2rem" }} start={19}>
          <li>
            आपकी गोपनीयता की सुरक्षा के लिए HDI, प्रतिबद्ध है। Go Nisha Go गेम ऐप या Go Nisha Go माइक्रोसाइट के संबंध
            में आपके द्वारा प्रदान की गई किसी जानकारी को संभालने पर लागू शर्तें, हमारी गोपनीयता नीति में वर्णित हैं।
          </li>
        </ol>

        <p style={{ fontWeight: 700, paddingTop: "20px" }}>दायित्व की सीमा</p>

        <ol style={{ paddingLeft: "2rem" }} start={20}>
          <li>
            आयु के अनुसार उपयुक्त सामग्री सुनिश्चित करने के लिए, Go Nisha Go गेम ऐप को जेंडर और किशोर स्वास्थ्य
            विशेषज्ञों के सहयोग से निर्मित किया गया है, तथा लक्षित दर्शकों के साथ मिलकर डिज़ाइन और परीक्षण किया गया है।
            हालांकि, उपलब्ध कराई गई सामग्री की पूर्णता और वर्तमान सत्यता के लिए HDI उत्तरदायी नहीं होगा।
          </li>

          <li>
            कानून द्वारा अनुमत अधिकतम सीमा तक, आप Go Nisha Go गेम ऐप और Go Nisha Go माइक्रोसाइट का अपने जोखिम पर उपयोग
            करेंगे, और आपके द्वारा इनका उपयोग किए जाने के कारण आपके लिए किन्हीं उत्पन्न लागतों या हानियों के लिए HDI
            उत्तरदायी नहीं होगा।
          </li>

          <li>
            इन सेवा की शर्तों के अंतर्गत अपने अधिकारों का प्रयोग करने में HDI की विफलता या विलम्ब, इनको छोड़ना नहीं माना
            जाएगा और HDI अपने अधिकारों को लागू करने का हकदार बना रहेगा।
          </li>
        </ol>

        <p style={{ fontWeight: 700, paddingTop: "20px" }}>विविध</p>

        <ol style={{ paddingLeft: "2rem" }} start={23}>
          <li>
            सेवा की शर्तें और उनके संबंध में किसी विवाद पर भारतीय कानून लागू है। आप और HDI दोनों, भारतीय न्यायालय के
            गैर-विशिष्ट क्षेत्राधिकार को सबमिट करने के लिए सहमत हैं।
          </li>

          <li>
            सेवा की इन शर्तों का कोई भाग या प्रावधान अवैध, अमान्य या अप्रवर्तनीय होने या हो जाने की स्थिति में यह सेवा
            की शर्तों के समस्त अन्य प्रावधानों और भागों की वैधता, मान्यता और प्रवर्तनीयता को प्रभावित नहीं करेगा।
          </li>
        </ol>

        <p style={{ fontWeight: 700, paddingTop: "20px" }}>
          अंतिम बार <u> 31 मई 2022</u> को संशोधित किया गया
        </p>

        <br />
        <br />
        <br />
      </div>
    </div>
  );
};
