import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { MY_CAREER_SLUG, MY_HEALTH_SLUG, RELATIONSHIP_SLUG, LIFESTYLE_SLUG } from "../constants";
import TOSModal from "./TOSModal";
import PrivacyModal from "./PrivacyModal";
import CreditsModal from "./CreditsModal";
import openInNewTab from "../utils/OpenInNewTab";
import { Flex, Text, Box, Image, Link as Clink } from "@chakra-ui/react";

export default function Footer() {
  const { t, i18n } = useTranslation();
  const [openTosModal, setOpenTosModal] = useState(false);
  const [openPrivacy, setOpenPrivacy] = useState(false);
  const [openGreivanceOfficer, setOpenGreivanceOfficer] = useState(false);
  const [openCredits, setOpenCredits] = useState(false);

  const [selectedLanguage, setSelectedLanguage] = React.useState(i18n.language || "en");

  React.useEffect(() => {
    setSelectedLanguage(i18n.language);
  }, [i18n.language]);

  const changeLanguage = (lang) => {
    setSelectedLanguage(lang);
    localStorage.setItem("lang", lang);
    i18n.changeLanguage(lang);
  };

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  useEffect(() => {
    const path = location.search;

    // console.log(path, location);
    if (path.includes("tos")) {
      setOpenTosModal(true);
    } else if (path.includes("privacy-policy")) {
      setOpenPrivacy(true);
      setOpenGreivanceOfficer(false);
    } else if (path.includes("grievance-officer")) {
      setOpenPrivacy(true);
      setOpenGreivanceOfficer(true);
    } else if (path.includes("credits")) {
      setOpenCredits(true);
    }
  }, [location]);

  return (
    <>
      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-xl-4 text-center" style={{ alignItems: "center" }}>
              <div className="footer-section">
                <Box className="abt-wid" mx="auto" my="auto">
                  <Flex
                    direction="row"
                    justify="space-between"
                    borderBottomWidth="1px"
                    borderBottomColor="gray.500"
                    className="row"
                    width="100%"
                    marginBottom="20px"
                    paddingBottom="40px">
                    <Link to="/" className={`${splitLocation[1] === "" ? "active" : ""} col-12 col-md-6 footer-img`}>
                      {" "}
                      <Image src="/assets/img/GNG_Logo.png" height="72px" />
                    </Link>{" "}
                    <a
                      target="blank"
                      className="col-12 col-md-6 footer-img"
                      style={{ margin: "auto" }}
                      onClick={() => openInNewTab(t("COMMON.LINKS.PLAYSTORE"))}>
                      <Image src="/assets/img/GooglePlay.svg" height="36px" marginTop="20px" />
                    </a>
                  </Flex>

                  <Flex width="350px">
                    <Text textAlign="left" fontSize="12px" fontFamily="body">
                      {t("FOOTER.THIS_WEBISTE_TEXT")}
                    </Text>
                  </Flex>

                  <Flex className="mmb-6 row" direction="row" justify="space-between" marginTop="40px">
                    <a
                      target="blank"
                      className="col-12 col-md-6 footer-img"
                      onClick={() => openInNewTab("https://www.usaid.gov/")}>
                      <Image src="/assets/img/USAID_Logo_White.png" height="50px" />
                    </a>

                    <a
                      target="blank"
                      className="col-12 col-md-6 footer-img"
                      onClick={() => openInNewTab("https://www.gameofchoicenotchance.com/")}>
                      <Image src="/assets/img/GOC_Logo.png" height="42px" marginBottom="8px" />
                    </a>
                  </Flex>
                </Box>
              </div>
            </div>

            <div className="col-12 col-xl-4 footer-border footer-border-top">
              <div className="footer-section">
                <div className="footer_list-wrapper dmb-6 mx-auto footer__contact">
                  <ul className="footer__contact-col" style={{ maxWidth: "200px" }}>
                    <li> {t("FOOTER.LINKS")} </li>
                    <li>
                      {" "}
                      <Link to="/" className={`${splitLocation[1] === "" ? "active" : ""}`}>
                        {" "}
                        {t("FOOTER.HOME")}{" "}
                      </Link>{" "}
                    </li>
                    <li>
                      {" "}
                      <Link
                        to={"/" + MY_HEALTH_SLUG}
                        className={`${splitLocation[1] === MY_HEALTH_SLUG ? "active" : ""}`}>
                        {" "}
                        {t("COMMON.MY_HEALTH")}{" "}
                      </Link>{" "}
                    </li>
                    <li>
                      {" "}
                      <Link
                        to={"/" + MY_CAREER_SLUG}
                        className={`${splitLocation[1] === MY_CAREER_SLUG ? "active" : ""}`}>
                        {" "}
                        {t("COMMON.MY_CAREER")}{" "}
                      </Link>{" "}
                    </li>
                    <li>
                      {" "}
                      <Link
                        to={"/" + RELATIONSHIP_SLUG}
                        className={`${splitLocation[1] === RELATIONSHIP_SLUG ? "active" : ""}`}>
                        {" "}
                        {t("COMMON.RELATIONSHIPS")}{" "}
                      </Link>{" "}
                    </li>
                    <li>
                      {" "}
                      <Link
                        to={"/" + LIFESTYLE_SLUG}
                        className={`${splitLocation[1] === LIFESTYLE_SLUG ? "active" : ""}`}>
                        {" "}
                        {t("COMMON.LIFESTYLES")}
                      </Link>{" "}
                    </li>
                    <li>
                      {" "}
                      <Link to="/my-channel" className={`${splitLocation[1] === "my-channel" ? "active" : ""}`}>
                        {" "}
                        {t("COMMON.NEWS")}
                      </Link>{" "}
                    </li>
                    <li>
                      {" "}
                      <Link to="/just4you" className={`${splitLocation[1] === "just4you" ? "active" : ""}`}>
                        {t("COMMON.JUST4YOU")}
                      </Link>{" "}
                    </li>
                    {/* <li>
                    {" "}
                    <Link
                      to="/support"
                      className={`${
                        splitLocation[1] === "support" ? "active" : ""
                      }`}
                    >
                      {" "}
                      {t("COMMON.SUPPORT")}{" "}
                    </Link>{" "}
                  </li> */}
                    <li>
                      {" "}
                      <Link to="/faqs" className={`${splitLocation[1] === "faqs" ? "active" : ""}`}>
                        {" "}
                        {t("COMMON.FAQ")}{" "}
                      </Link>{" "}
                    </li>
                    <li>
                      {" "}
                      <Link to="/rateus" className={`${splitLocation[1] === "rateus" ? "active" : ""}`}>
                        {" "}
                        {t("COMMON.RATE")}{" "}
                      </Link>{" "}
                    </li>
                  </ul>

                  <ul className="lang-list" style={{ maxWidth: "200px" }}>
                    <li> {t("FOOTER.LANGUAGE")} </li>
                    <li>
                      {" "}
                      <a
                        className={`${selectedLanguage === "en" ? "active" : ""}`}
                        onClick={() => changeLanguage("en")}>
                        English
                      </a>{" "}
                    </li>
                    <li>
                      {" "}
                      <a
                        className={`${selectedLanguage === "hin" ? "active" : ""}`}
                        onClick={() => changeLanguage("hin")}>
                        {" "}
                        हिंदी
                      </a>{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-12 col-xl-4  footer-border-top">
              <div className="footer-section">
                <div className="footer__contact plr5">
                  <div className="footer__contact-col" style={{ maxWidth: "200px" }}>
                    <h5> {t("FOOTER.CONNECT")} </h5>
                    <div className="social">
                      <SocialLink
                        url={t("COMMON.LINKS.FB")}
                        imgURL="/assets/img/Facebook_color.svg"
                        title={t("FOOTER.FB")}
                      />

                      <SocialLink
                        url={t("COMMON.LINKS.INSTA")}
                        imgURL="/assets/img/Insta_color.svg"
                        title={t("FOOTER.IN")}
                      />

                      <SocialLink
                        url={t("COMMON.LINKS.YOUTUBE")}
                        imgURL="/assets/img/YouTube_color.svg"
                        title={t("FOOTER.YT")}
                      />

                      {/* <SocialLink
                      url={"?grievance-officer"}
                      imgURL="/assets/img/GrievanceOfficer.svg"
                      title={t("FOOTER.GRIEVANCE")}
                      notExternal={true}
                    /> */}

                      <Link to="?grievance-officer" mb="2">
                        <Flex align="center" maxWidth="120px" alignItems="center">
                          <Image src="/assets/img/EmailUs.svg" alt="" mr={2} mt="3px" />
                          <Text color="#b3b3b3" fontSize="14px">
                            {t("FOOTER.EMAIL")}
                          </Text>
                        </Flex>
                      </Link>
                    </div>
                  </div>

                  <div className="helplines" style={{ maxWidth: "200px" }}>
                    <h5> {t("FOOTER.USEFUL_HELPLINE")} </h5>

                    <div className="social" style={{ width: "40%" }}>
                      <ContactLinks
                        url={"tel:" + t("COMMON.LINKS.HELPLINE_1")}
                        imgURL="/assets/img/Call_Icon.svg"
                        title={t("FOOTER.HELPLINE_1")}
                        subtitle={t("COMMON.LINKS.HELPLINE_1")}
                      />

                      <ContactLinks
                        url={"tel:" + t("COMMON.LINKS.HELPLINE_2")}
                        imgURL="/assets/img/Call_Icon.svg"
                        title={t("FOOTER.HELPLINE_2")}
                        subtitle={t("COMMON.LINKS.HELPLINE_2")}
                      />

                      <ContactLinks
                        url={"tel:" + t("COMMON.LINKS.HELPLINE_3")}
                        imgURL="/assets/img/Call_Icon.svg"
                        title={t("FOOTER.HELPLINE_3")}
                        subtitle={t("COMMON.LINKS.HELPLINE_3")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="text-center pb-2">
          &copy; {t("FOOTER.COPYRIGHT")} | <br class="d-md-none" />
          <Link to="?tos">
            <span>{t("FOOTER.TOS")} </span>{" "}
          </Link>
          |
          <Link to="?privacy-policy">
            <span> {t("FOOTER.PRIVACY")} </span>
          </Link>
          |
          <Link to="?credits">
            <span> {t("FOOTER.CREDITS")} </span>
          </Link>
        </div>
      </footer>
      <TOSModal openModal={openTosModal} closeModal={() => setOpenTosModal(false)} />
      <PrivacyModal
        openModal={openPrivacy}
        closeModal={() => setOpenPrivacy(false)}
        scrollToGrievanceOfficer={openGreivanceOfficer}
      />
      <CreditsModal openModal={openCredits} closeModal={() => setOpenCredits(false)} />
    </>
  );
}

const SocialLink = (props) => {
  return (
    <Clink href={props.url} isExternal={props.notExternal ? false : true} mb="2">
      <Flex align="center" maxWidth="120px">
        <Image src={props.imgURL} alt="" mr={2} />
        <Text color="#b3b3b3" fontSize="14px">
          {props.title}
        </Text>
      </Flex>
    </Clink>
  );
};

const ContactLinks = (props) => {
  return (
    <Clink href={props.url} mb="2">
      <Flex align="center" maxWidth="200px" alignItems="flex-start">
        <Image src={props.imgURL} alt="" mr={2} mt="3px" />
        <Text color="#b3b3b3" fontWeight="normal" fontSize="14px">
          {props.title} <br />
          <span className="oclr">{props.subtitle}</span>
        </Text>
      </Flex>
    </Clink>
  );
};
