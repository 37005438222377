import React from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";

export default function Banner() {
  const { t, i18n } = useTranslation();

  const [showVideo, setShowVideo] = React.useState(false);

  return (
    <section className="hero">
      {showVideo ? (
        <iframe
          className="hero__video"
          src={`https://www.youtube.com/embed/${t("COMMON.LINKS.BANNER_YOUTUBE_VIDEO_ID")}?autoplay=1`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
          allowFullScreen="allowFullScreen"></iframe>
      ) : (
        <div>
          <img src="./assets/img/MainBanner.png" className="hero__banner" alt="" srcSet="" />

          <a
            style={{ cursor: "pointer" }}
            className="hero__content"
            onClick={() => {
              setShowVideo(true);
            }}>
            <img src="/assets/img/VideoPlay.png" className="img-fluid" alt="" srcSet="" />
            <p style={{ fontSize: "20px" }}>
              {t("HOMEPAGE.CLICK_HERE_TO")} <br /> {t("HOMEPAGE.WATCH_VIDEO")}
            </p>
          </a>
        </div>
      )}
    </section>
  );
}
