import React, { useEffect } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import openInNewTab from "../utils/OpenInNewTab";
import { MY_HEALTH_SLUG, MY_CAREER_SLUG, RELATIONSHIP_SLUG, LIFESTYLE_SLUG, MY_CHANNEL_SLUG } from "../constants";
import { Box } from "@chakra-ui/react";

export default function Header({ history }) {
  const { t, i18n } = useTranslation();

  let [searchParams, setSearchParams] = useSearchParams();
  const lang = searchParams.get("lang") || i18n.language;
  console.log(lang);

  const [navbarCollapse, navbarCollapseToggle] = React.useState(false);

  const [selectedLanguage, setSelectedLanguage] = React.useState(lang || "en");

  const location = useLocation();

  const { pathname } = location;

  const splitLocation = pathname.split("/");

  // console.log(splitLocation[1]);

  useEffect(() => {
    if (navbarCollapse) navbarCollapseToggle(false);
  }, [location]);

  const changeLanguage = (lang) => {
    localStorage.setItem("lang", lang);
    i18n.changeLanguage(lang);
  };

  useEffect(() => {
    changeLanguage(lang);
    searchParams.delete("lang");
    setSearchParams(searchParams);
  }, []);

  return (
    <header className="w-100">
      <nav className={`navbar navbar-expand-xl ${i18n.language == "hin" ? "navbar--hindi" : ""}`}>
        <Link to="/" className="navbar-brand" href="/">
          {<img src="/assets/img/GNG_Logo.png" alt="" />}
          {/* {<div class="mainlogo"></div>} */}
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          onClick={() => {
            navbarCollapseToggle(!navbarCollapse);
          }}>
          <i className="fa fa-bars" aria-hidden="true"></i>
        </button>

        <div className={`collapse navbar-collapse ${navbarCollapse ? "show" : ""}`} id="collapsibleNavbar">
          <Box ml="auto"></Box>
          <ul className="navbar-nav ml-auto align-cen">
            <li className="nav-item mr-2">
              <Link to="/" className={`nav-link nav-home-link ${splitLocation[1] === "" ? "active" : ""}`}>
                {<div class="Homelogo"></div>}
              </Link>
            </li>
          </ul>

          <ul className="navbar-nav social-nav">
            <li className="nav-item mr-2 ml-2">
              <select
                value={i18n.language}
                variant="unstyled"
                className="nav-link select-language"
                onChange={(e) => {
                  setSelectedLanguage(e.target.value);
                  changeLanguage(e.target.value);
                }}>
                <option value="en">ENG</option>
                <option value="hin"> हिंदी </option>
              </select>
            </li>
          </ul>

          <ul className="navbar-nav">
            <li className="nav-item ml-2">
              <Link
                to={"/" + MY_HEALTH_SLUG}
                className={`nav-link ${splitLocation[1] === MY_HEALTH_SLUG ? "active" : ""}`}>
                {t("COMMON.MY_HEALTH")}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={"/" + MY_CAREER_SLUG}
                className={`nav-link ${splitLocation[1] === MY_CAREER_SLUG ? "active" : ""}`}>
                {t("COMMON.MY_CAREER")}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={"/" + RELATIONSHIP_SLUG}
                className={`nav-link ${splitLocation[1] === RELATIONSHIP_SLUG ? "active" : ""}`}>
                {t("COMMON.RELATIONSHIPS")}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={"/" + LIFESTYLE_SLUG}
                className={`nav-link ${splitLocation[1] === LIFESTYLE_SLUG ? "active" : ""}`}>
                {t("COMMON.LIFESTYLES")}
              </Link>
            </li>
            <li className="nav-item mr-2">
              <Link to="/my-channel" className={`nav-link ${splitLocation[1] === "my-channel" ? "active" : ""}`}>
                {t("COMMON.NEWS")}
              </Link>
            </li>
          </ul>

          <ul className="navbar-nav">
            <li className="nav-item ml-2">
              <Link to="/just4you" className={`nav-link ${splitLocation[1] === "just4you" ? "active" : ""}`}>
                {t("COMMON.JUST4YOU")}
              </Link>
            </li>
            {/* <li className="nav-item mr-2">
              <Link
                to="/support"
                className={`nav-link ${
                  splitLocation[1] === "support" ? "active" : ""
                }`}
              >
                {t("COMMON.SUPPORT")}
              </Link>
            </li> */}
            <li className="nav-item">
              <Link to="/faqs" className={`nav-link ${splitLocation[1] === "faqs" ? "active" : ""}`}>
                {t("COMMON.FAQ")}
              </Link>
            </li>
            <li className="nav-item mr-2">
              <Link to="/rateus" className={`nav-link ${splitLocation[1] === "rateus" ? "active" : ""}`}>
                {t("COMMON.RATE")}
              </Link>
            </li>
          </ul>

          <ul className="navbar-nav social-nav">
            <li className="nav-item ml-2">
              <a target="blank" onClick={() => openInNewTab(t("COMMON.LINKS.FB"))} className="nav-link">
                <img src="/assets/img/Facebook_color.svg" alt="" />
              </a>
            </li>
            <li className="nav-item">
              <a target="blank" onClick={() => openInNewTab(t("COMMON.LINKS.INSTA"))} className="nav-link">
                <img src="/assets/img/Insta_color.svg" alt="" />
              </a>
            </li>
            <li className="nav-item mr-2">
              <a target="blank" onClick={() => openInNewTab(t("COMMON.LINKS.YOUTUBE"))} className="nav-link">
                <img src="/assets/img/YouTube_color.svg" alt="" />
              </a>
            </li>
          </ul>

          <ul className="navbar-nav social-nav">
            <li className="nav-item ml-2">
              <Link to="/search" className={`nav-link ${splitLocation[1] === "search" ? "active" : ""}`}>
                {<div class="searchlogo"></div>}
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}
