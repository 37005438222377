import API from "../api";
import { FETCH_PARTNERS, FETCH_PARTNERS_COMPLETE, FETCH_NEWS, FETCH_NEWS_COMPLETE } from "../constants/action";
import { formatPartners, formatNews } from "../helper/formatTopics";

export async function doFetchPartners(dispatch, category = "") {
  dispatch({ type: FETCH_PARTNERS });

  try {
    let response = await API.get("/partners?per_page=100&category=" + category);
    dispatch({
      type: FETCH_PARTNERS_COMPLETE,
      payload: { partners: formatPartners(response.data) },
    });
  } catch (error) {
    dispatch({ type: FETCH_PARTNERS_COMPLETE, payload: { partners: [] } });
  }
}

export async function doFetchNews(dispatch) {
  dispatch({ type: FETCH_NEWS });

  try {
    let response = await API.get("/news?per_page=100");

    // console.log(response.data);

    dispatch({
      type: FETCH_NEWS_COMPLETE,
      payload: { news: formatNews(response.data) },
    });
  } catch (error) {
    dispatch({ type: FETCH_NEWS_COMPLETE, payload: { news: [] } });
  }
}
