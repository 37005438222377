export const CreditDataEnglish = [
  {
    name: "HOWARD DELAFIELD INTERNATIONAL:",
    role: "Prime/Lead Organization",
    people: [
      {
        name: "Susan Howard",
        role: "Co-Founder/Managing Director and Executive Producer/GOC Team Lead",
      },
      {
        name: "Sylvia Delafield",
        role: "Co-Founder/Director and GOC Senior Partnerships Advisor",
      },
      {
        name: "Lalita Shankar",
        role: "Regional Director for Asia and Middle East",
      },
      {
        name: "Kavita Ayyagari",
        role: "India Team Lead and HDI Country Director",
      },
      {
        name: "Jeannette Cachan",
        role: "Management and MEL Advisor",
      },
      {
        name: "Laurette Cucuzza",
        role: "Senior Technical Advisor",
      },
      {
        name: "Joy Pollock",
        role: "Finance and Operations Director",
      },
      {
        name: "Rani Bopanna",
        role: "Senior Manager, India Finance and Operations",
      },
      {
        name: "Meghna Chaudhury",
        role: "Digital and Youth Engagement Specialist",
      },
      {
        name: "Feras A. Batarseh",
        role: "Senior Advisor for Game Data Analytics",
      },
      {
        name: "Chih-Hao Huang",
        role: "Game Data Engineer",
      },
      {
        name: "Mahesh Karra",
        role: "Senior Advisor, Game Outcome Evaluation",
      },
      {
        name: "Patrick Baxter",
        role: "Research Advisor, Game Outcome Evaluation",
      },
      {
        name: "Cynthia Hannah",
        role: "Senior Advisor, Communication and Creative",
      },
      {
        name: "Debby Wells",
        role: "Editor",
      },
      {
        name: "Aditee Oli",
        role: "Project Associate",
      },
      {
        name: "Yash Chauhan",
        role: "Associate Product Manager",
      },
    ],
  },
  {
    name: "STUDIOSKULL:",
    role: "All Music Production at Studioskull",
    people: [
      {
        name: "Daniel B. George",
        role: "Music",
      },
      {
        name: "Protijyoti Ghosh",
        role: "Music Production",
      },
      {
        name: "Ujjwal Kashyap",
        role: "Music Production",
      },
      {
        name: "Hopun Saikia",
        role: "Mix And Master",
      },
    ],
  },
  {
    name: "VIHARA INNOVATION NETWORK:",
    role: "Creative Content, Design Strategy and Prototype Testing.",
    people: [
      {
        name: "Namita Mohandas",
        role: "Creative Lead",
      },
      {
        name: "Ameya Chaturvedi",
        role: "Content Manager",
      },
      {
        name: "Tushar Abhichandani",
        role: "Script Expert",
      },
      {
        name: "Snigdha Jha",
        role: "Design Manager",
      },
      {
        name: "Srividya Venkatraman",
        role: "Design Researcher",
      },
      {
        name: "Jocelyn Joseph",
        role: "Design Researcher",
      },
      {
        name: "Sadia Tabassum",
        role: "Design Researcher",
      },
      {
        name: "Pradhan Thandra",
        role: "Art Director",
      },
      {
        name: "Indrayudh Sengupta",
        role: "Visual Designer",
      },
      {
        name: "Swapnesh Samaiya",
        role: "Game Designer",
      },
      {
        name: "Swati Mishra",
        role: "Research Intern",
      },
      {
        name: "Abhishek Solanki",
        role: "UX Intern",
      },
      {
        name: "Arpit Chhikara",
        role: "Writer",
      },
      {
        name: "Avani Tavargeri",
        role: "Digital Prototyping Manager",
      },
      {
        name: "Radhika Kannan",
        role: "Operations Manager",
      },
      {
        name: "Kamini Kumari",
        role: "Research Coordinator Ajmer (Mahila Jan Adhikar Samiti)",
      },
      {
        name: "Yashoda Gurjar",
        role: "Research Coordinator Ajmer (Mahila Jan Adhikar Samiti)",
      },
      {
        name: "Meena Kamat",
        role: "Research Coordinator Delhi (Feminist Approach to Technology)",
      },
      {
        name: "Pooja JP",
        role: "Research Coordinator Delhi (Feminist Approach to Technology)",
      },
      {
        name: "Surbhi Kumari",
        role: "Research Coordinator Patna (Feminist Approach to Technology)",
      },
      {
        name: "Sushmita Mandal",
        role: "Research Coordinator Patna (Feminist Approach to Technology)",
      },
      {
        name: "Babita Kumari",
        role: "Research Coordinator Delhi (Matri Sudha)",
      },
      {
        name: "Asha Rani",
        role: "Research Coordinator Delhi (Matri Sudha)",
      },
      {
        name: "Baby Husain",
        role: "Research Coordinator Delhi (Matri Sudha)",
      },
    ],
  },
  {
    name: "METAMERSIVE:",
    role: "Game Development Studio",
    people: [
      {
        name: "Ashima Misri",
        role: "Producer",
      },
      {
        name: "Alankrith Shankar",
        role: "Line Producer - Game and Sound Design",
      },
      {
        name: "Ajinkya Mankar",
        role: "Lead Game Developer",
      },
      {
        name: "Sujit Kadam",
        role: "Senior Game Developer",
      },
      {
        name: "Smit Vaghela",
        role: "Game Developer",
      },
      {
        name: "Shailan Aranha",
        role: "Game Developer",
      },
      {
        name: "Rishi Dave",
        role: "Game Developer",
      },
      {
        name: "Swapnil Mayekar",
        role: "Game Developer",
      },
      {
        name: "Rohit Hemane",
        role: "Game Developer",
      },
      {
        name: "Satbir Singh",
        role: "Systems Architect",
      },
      {
        name: "Anosh Master",
        role: "Lead - User Interface, User Experience and Graphic Design",
      },
      {
        name: "Elesha Vartak",
        role: "UI/UX Designer",
      },
      {
        name: "Rishikesh Lokare",
        role: "Lead - 3D Environments Design",
      },
      {
        name: "Siddharth Chari",
        role: "3D Environment Designer",
      },
      {
        name: "Shubham Nikam",
        role: "3D Environment Designer",
      },
      {
        name: "Reuben Das",
        role: "Lead - Character Design",
      },
      {
        name: "Swapnil Welling",
        role: "Lead - Animations",
      },
      {
        name: "Sagar Poojary",
        role: "Animator",
      },
      {
        name: "Vivek Kadwane",
        role: "Animator",
      },
      {
        name: "Justin Pinto",
        role: "Testing and QA",
      },
      {
        name: "Abhishek Vaidyanathan",
        role: "Lead - Systems Backend",
      },
      {
        name: "Rohit Kasi",
        role: "Line Producer",
      },
      {
        name: "Sakshi Shinde",
        role: "Finance and Accounts Manager",
      },
      {
        name: "Sneha Mohite",
        role: "Recruitment and HR Manager",
      },
      {
        name: "Siddharth Banerjee",
        role: "Executive Producer",
      },
    ],
  },
  {
    name: "NAM ER TECHNOLOGY, LLC:",
    role: "Job Simulator - Mini Game: Teacher, Teacher!",
    people: [
      {
        name: "Sang Nam",
        role: "Game Design Advisor, Lead Game Designer",
      },
      {
        name: "Student Team - George Mason University:",
        org: {
          people: [
            {
              name: "Nate Garner",
              role: "Lead Environment and Prop Artist",
            },
            {
              name: "Conner Stern",
              role: "Lead Character Artist",
            },
            {
              name: "Joshua DeRosett",
              role: "Lead Programmer",
            },
            {
              name: "Casey Davis",
              role: "Game Artist",
            },
            {
              name: "Kimi Zadegan",
              role: "Game Artist",
            },
            {
              name: "Benjamin Johnston",
              role: "Game Programmer",
            },
            {
              name: "Alan Sherba",
              role: "Game Programmer",
            },
            {
              name: "Asher Pinson",
              role: "Game Programmer",
            },
            {
              name: "Paul Hiniker",
              role: "Sound Design/Game Music Composer",
            },
          ],
        },
      },
    ],
  },
  {
    name: "GIRL EFFECT:",
    role: "Formative and Psychographic Research",
    people: [
      {
        name: "Aparna Raj",
        role: "Research Manager",
      },
      {
        name: "Shraddha Kulkarni",
        role: "Project Manager",
      },
      {
        name: "Sneha Chaturvedi",
        role: "Country Director, Content Lead",
      },
      {
        name: "Vaishnavi Lotlikar",
        role: "Marketing Strategist",
      },
    ],
  },
  {
    name: "CYCLE TECHNOLOGIES:",
    role: "CycleBeads App",
    people: [
      {
        name: "Leslie Heyer",
        role: "Research and Evaluation Lead, WG",
      },
      {
        name: "Alev Sezer-Jacobs",
        role: "Director, Marketing",
      },
    ],
  },
  {
    name: "INFINITY INTERNET PVT. LTD.:",
    role: "AskParo ChatBot",
    people: [
      {
        name: "Subhadeep Bhattacharyya",
        role: "CEO",
      },
      {
        name: "Teena Thankachan",
        role: "Content Writer and Project Manager",
      },
      {
        name: "Yogesh Chavan",
        role: "Project Manager",
      },
      {
        name: "Aniruddha Roy Choudhury",
        role: "Project Manager",
      },
      {
        name: "Darpan Raut",
        role: "Backend Developer",
      },
      {
        name: "Rodrick Hilarian",
        role: "Frontend Developer",
      },
      {
        name: "Sanjeev Sebastian",
        role: "Backend Developer",
      },
    ],
  },
  {
    name: "MULLEN LOWE LINTAS GROUP (MLLG):",
    role: "Special acknowledgement to MLLG for Logo Design for Go Nisha Go",
  },
  {
    name: "FHI360:",
    role: "Special Thanks to FHI360 for Research for Scale Project (R4S) for financial and research technical support",
    people: [
      {
        name: "Barbara Sow",
        role: "Project Director",
      },
      {
        name: "Aurelie Brunie",
        role: "Scientist, Senior Research Lead",
      },
      {
        name: "Emily Namey",
        role: "Associate Scientist, Associate Director Research",
      },
    ],
  },
  {
    name: "POPULATION COUNCIL CONSULTING PVT LTD:",
    role: "Game Impact Evaluation",
    people: [
      {
        name: "Niranjan Saggurti",
        role: "Research Evaluation Advisor",
      },
      {
        name: "Madhusudana Battala",
        role: "Principal Investigator",
      },
      {
        name: "Nizamuddin Khan",
        role: "Co-Investigator",
      },
      {
        name: "Ananya Saha",
        role: "Co-Principal Investigator",
      },
      {
        name: "Anil Paul",
        role: "Technical Advisor",
      },
    ],
  },
  {
    name: "RESTLESS DEVELOPMENT:",
    role: "Youth Engagement Partner",
    people: [
      {
        name: "Franklin Paul",
        role: "Strategy Lead",
      },
      {
        name: "Shagun Sharma",
        role: "Program Implementation Lead",
      },
      {
        name: "Soumya Mathew",
        role: "Communications/Campaigns Lead",
      },
    ],
  },
  {
    name: "LEGAL AND PROFESSIONAL ADVISORS:",
    role: "Special Acknowledgement to our Legal and Professional Advisors",
    people: [
      {
        name: "Taapsi Johri",
        role: "Partner,  Lex IP Care, LLP",
      },
      {
        name: "Andrew Mohr",
        role: "Partner,  Morris, Manning and Martin, LLP",
      },
      {
        name: "Kelly Kroll",
        role: "Partner,  Morris, Manning and Martin, LLP",
      },
      {
        name: "Olga Wall",
        role: "Principal, Avallon, LLC",
      },
    ],
  },
  {
    name: "USAID:",
    role: "Special Acknowledgement: This game was made possible by the generous support of the American people through the United States Agency for International Development (USAID). The contents do not necessarily reflect the views of USAID or the United States government.",
  },
];
