import React, { useRef } from "react";
import TopicCard from "../components/TopicCard";
import PartnerCard from "../components/PartnerCard";
import { useSelector, useDispatch } from "react-redux";
import { doFetchCategories, doFetchTopics } from "../action/topics";
import { doFetchPartners } from "../action/partners";
import { useTranslation } from "react-i18next";
import Loading from "../components/Loading";
import NotFound from "./NotFound";

import { useParams, Link, useLocation } from "react-router-dom";
import Just4You from "../components/homepage/Just4You";
import { Text, Box, Flex } from "@chakra-ui/react";
import Banner from "../components/Banner";

export default function TopicCategory({ id, data }) {
  const topicRef = useRef();

  const { categorySlug } = useParams();

  const { t, i18n } = useTranslation();

  const categories = useSelector((state) => state.topics.categories);
  const isCategoriesLoading = useSelector((state) => state.topics.isCategoriesLoading);
  const isCategoriesFetched = useSelector((state) => state.topics.isCategoriesFetched);
  const topics = useSelector((state) => state.topics.topics);
  const partners = useSelector((state) => state.topics.partners);
  const isPartnersLoading = useSelector((state) => state.topics.isPartnersLoading);

  const isTopicsLoading = useSelector((state) => state.topics.isTopicsLoading);
  const dispatch = useDispatch();

  const location = useLocation();

  React.useEffect(() => {
    doFetchCategories(dispatch);
    doFetchPartners(dispatch);

    const path = location.search;
    if (path.includes("topic")) {
      setTimeout(() => {
        topicRef.current.scrollIntoView({ behavior: "smooth" });
      }, 1000);
    }
  }, []);

  // FIND CATEGORY
  const category = categories.find((cat) => {
    return cat.slug == categorySlug;
  });

  React.useEffect(() => {
    if (category && topics.length === 0 && !isTopicsLoading) {
      doFetchTopics(dispatch, categorySlug);
    }
  }, [category]);

  if (!category) {
    if (!isCategoriesFetched) return <Loading />;
    return <NotFound />;
  }

  const filteredTopics = topics.filter((tpc) => tpc.categories.includes(category.id));

  let filteredPartners = partners.filter((tpc) => tpc.categories.includes(category.id));

  return (
    <div className="categorypage">
      {/* <iframe
        className="hero-video"
        src={`https://www.youtube.com/embed/videoseries?list=${
          i18n.language == "en"
            ? category.video_playlist_in_english
            : category.video_playlist_in_hindi
        }`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
        allowFullScreen="allowFullScreen"
      ></iframe> */}

      <Banner imgurl={category.banner_img} />

      <div className="container">
        <div className="content">
          <div className="content__title">
            <Text fontSize={["30px"]} fontFamily="heading" mt="36px" mb="14px" ref={topicRef}>
              {i18n.language == "en" ? category.name : category.name_in_hindi}{" "}
            </Text>
          </div>
          <div className="content__para">
            <Text fontSize="18px" fontFamily="body">
              {i18n.language == "en" ? category.description : category.description_in_hindi}
            </Text>
          </div>

          <Box pt="30px"></Box>
          <hr style={{ marginBottom: "0px" }} />

          <div className="content__wrapper">
            {/* <Flex wrap="wrap" justify="space-between"> */}
            {filteredTopics.map((topic) => {
              return <TopicCard key={topic.id} {...topic} category={categorySlug} />;
            })}
            {/* </Flex> */}
          </div>
        </div>

        <hr />
      </div>

      {filteredPartners.length ? <Just4You partners={filteredPartners} /> : <> </>}
    </div>
  );
}
