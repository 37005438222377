import React, { useState, useEffect, useRef } from "react";
import { Box, Flex, Text, Image, Divider, Link as CLink } from "@chakra-ui/react";
import Slider from "react-slick";
import API from "../../api";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";

const MeetMembers = () => {
  const { t, i18n } = useTranslation();
  const [members, setMembers] = useState([]);
  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    API.get("/members")
      .then((res) => {
        if (res && res.data && res.data.length) {
          let newMembers = res.data.map((el) => {
            return { ...el, title: el.title.rendered, ...el.acf };
          });

          if (newMembers && newMembers.length) {
            setMembers(newMembers);
            setisLoading(false);
          }
        }
      })
      .catch((err) => console.log(err));
  }, []);

  if (isLoading) return <> </>;

  return (
    <Box
      my="30px"
      px="30px"
      py="60px"
      backgroundSize="cover"
      backgroundPosition="center center"
      minHeight="474px"
      className="meetCard">
      <Box width="100%" maxWidth="1170px" mx="auto">
        <MembersSlider members={members} />

        <Divider color="white" marginBottom="60px" marginTop="30px" />

        <CTAGroup />
      </Box>
    </Box>
  );
};

export default MeetMembers;

const CTAGroup = () => {
  const { t, i18n } = useTranslation();

  const DownloadBtn = (type, link = "") => {
    const title = type == "play" ? "DOWNLOAD_FOR_FREE" : "WATCH_OUR_CHANNEL";
    const image = type == "play" ? "GooglePlay_Logo.png" : "YouTube_Logo.png";
    const linkURL = type == "play" ? "DOWNLOAD_LINK" : "CHANNEL_LINK";

    return (
      <Box mb={["60px", "60px", 0, 0]}>
        <CLink href={t(`HOMEPAGE.${linkURL}`)} isExternal={true}>
          <Flex
            bg="white"
            width="200px"
            height="80px"
            borderRadius="40px"
            border="1px solid  #E85900"
            align="center"
            justify="center"
            direction="column"
            boxShadow="0 0 10px 8px rgb(0 0 0 / 20%)">
            <Text fontFamily="heading" color="#E85900" fontSize="16px" mb={1}>
              {t(`HOMEPAGE.${title}`)}
            </Text>

            <Image src={`assets/img/${image}`} height="34px" />
          </Flex>
        </CLink>
      </Box>
    );
  };

  return (
    <Flex direction={["column", "column", "row", "row"]} align="center" justify="space-between">
      {DownloadBtn("play")}

      <Flex direction="row" align="center" justify="center" mt={0} mb={["60px", "60px", 0, 0]}>
        <Box textAlign="right" marginRight="1" fontFamily="heading" color="white" mr={2}>
          <Text fontSize="20px">{t("HOMEPAGE.SCAN")}</Text>
          <Text fontSize="26px" lineHeight="1rem">
            {t("HOMEPAGE.QR")}
          </Text>
          <Text fontSize="20px">{t("HOMEPAGE.CODE")}</Text>
        </Box>

        <Box>
          <Image src={`assets/img/GNG-QR_Code.png`} height="80px" />
        </Box>
      </Flex>

      {DownloadBtn("yt")}
    </Flex>
  );
};

const getvalueByLanguage = (i18n, el, property, propertInHindi = "") => {
  return i18n.language === "en" ? (property ? property : "") : propertInHindi ? propertInHindi : "";
};

const MembersSlider = (props) => {
  let members = props.members || [];
  const { t, i18n } = useTranslation();
  const [member, setMember] = useState({ ...members[0] });
  const [startPos, setStartPos] = useState(0);
  const [title, setTitle] = useState(getvalueByLanguage(i18n, members[0], "title", "title_in_hindi") || "");
  const [desc, setDesc] = useState(getvalueByLanguage(i18n, members[2], "description", "description_in_hindi") || "");
  const sliderRef = useRef(null);

  useEffect(() => {
    // console.log(sliderRef);
  }, [sliderRef]);

  useEffect(() => {
    setTitle(members[0].title || "");
    setDesc(members[0].description || "");
    setMember({ ...members[startPos] });
  }, [startPos]);

  useEffect(() => {
    // console.log(member);
  }, [member]);

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    className: "center",
    centerMode: true,
    centerPadding: "0px",
    infinite: true,
    initialSlide: 0,
    arrows: false,
    afterChange: (e) => {
      let pos = e;

      setStartPos(pos);
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    nextArrow: <ArrowSlide type="next" />,
    prevArrow: <ArrowSlide />,
  };

  members = [...members, ...members];

  return (
    <div>
      <Box marginTop="-2px" mx="auto" width="100%" maxWidth="700px" marginBottom="20px" className="meetDesc">
        <Text
          fontFamily="heading"
          textAlign="center"
          color="#AB2B00"
          fontSize="30px"
          mb={["10px", "10px", "10px", "10px"]}>
          {`${t("HOMEPAGE.MEET")}  ${i18n.language === "en" ? member?.title : member?.title_in_hindi}!`}
        </Text>

        <Text fontSize="20px" textAlign="center" fontFamily="heading" color="#AB2B00">
          {` ${i18n.language === "en" ? member?.description : member?.description_in_hindi}`}
        </Text>
      </Box>

      <Box maxWidth="725px" mx="auto" position="relative">
        {members.length >= 5 ? (
          <Slider ref={sliderRef} {...settings} className={`memberSliders active-${startPos}`}>
            {members.map((member, index) => (
              <MemberSlide
                className={`slide slide${1} ${startPos == index ? "centeredSlide" : ""}`}
                key={`${member.id}-${index}`}
                {...member}
              />
            ))}
          </Slider>
        ) : null}

        <Flex
          position="absolute"
          height="35px"
          width="35px"
          left={0}
          top={"65%"}
          transform="translate(-50%, -85%)"
          align="center"
          justify="center">
          <ArrowSlide
            onClick={() => {
              if (sliderRef && sliderRef?.current && sliderRef?.current?.slickPrev) {
                sliderRef?.current?.slickPrev();
              }
            }}
          />
        </Flex>

        <Flex
          position="absolute"
          height="35px"
          width="35px"
          right={0}
          top={"65%"}
          transform="translate(50%, -85%)"
          align="center"
          justify="center">
          <ArrowSlide
            type="next"
            onClick={() => {
              if (sliderRef && sliderRef?.current && sliderRef?.current?.slickNext) {
                sliderRef?.current?.slickNext();
              }
            }}
          />
        </Flex>
      </Box>
    </div>
  );
};

const MemberSlide = (props) => {
  return (
    <Box width="100%" textAlign="center" {...props} mx="auto">
      <Flex height="145px" align="center" justify="center">
        <Image src={props.image.url} />
      </Flex>
    </Box>
  );
};

export const ArrowSlide = (props) => {
  const { className, style, onClick, color, type } = props;
  return (
    <div className={className}>
      <Box
        cursor="pointer"
        color={color ? color : "gray.100"}
        fontWeight={["700", "700", "300", "300"]}
        onClick={onClick}
        fontSize={["32px", "32px", "32px", "42px"]}
        as={type === "next" ? BsChevronRight : BsChevronLeft}
      />
    </div>
  );
};
