export const CreditDataHindi = [
  {
    name: "हॉवर्ड डेलाफील्ड इंटरनेशनल:",
    role: "प्राइम/लीड आर्गेनाईजेशन",
    people: [
      {
        name: "सूसन हॉवर्ड",
        role: "सह-संस्थापक/प्रबंध निदेशक और कार्यकारी निर्माता/जी.ओ.सी टीम लीड",
      },
      {
        name: "सिल्विया डेलाफ़ील्ड",
        role: "सह-संस्थापक / निदेशक और जी.ओ.सी वरिष्ठ पार्टनरशिप्स सलाहकार",
      },
      {
        name: "ललिता शंकर",
        role: "एशिया और मध्य पूर्व के क्षेत्रीय निदेशक",
      },
      {
        name: "कविता अय्यागरी",
        role: "इंडिया टीम लीड और एच.डी.आई कंट्री निदेशक",
      },
      {
        name: "जेनेट कचान",
        role: "प्रबंधन और एम.ई.एल सलाहकार",
      },
      {
        name: "लॉरेट कुकुज़ा",
        role: "वरिष्ठ तकनीकी सलाहकार",
      },
      {
        name: "जॉय पौलक",
        role: "वित्त और संचालन निदेशक",
      },
      {
        name: "रानी बोपन्ना",
        role: "वरिष्ठ प्रबंधक, भारत वित्त और संचालन",
      },
      {
        name: "मेघना चौधरी",
        role: "डिजिटल और युवा जुड़ाव विशेषज्ञ",
      },
      {
        name: "फेरास ए. बतरसेह",
        role: "गेम डेटा एनालिटिक्स के लिए वरिष्ठ सलाहकार",
      },
      {
        name: "चिह-हाओ हुआंग",
        role: "गेम डेटा इंजीनियर",
      },
      {
        name: "महेश कार्रा",
        role: "वरिष्ठ सलाहकार, खेल परिणाम मूल्यांकन",
      },
      {
        name: "पैट्रिक बैक्सटर",
        role: "अनुसंधान सलाहकार, खेल परिणाम मूल्यांकन",
      },
      {
        name: "सिंथिया हैन्नाह",
        role: "वरिष्ठ सलाहकार, संचार और रचनात्मक",
      },
      {
        name: "डेबी वेल्स",
        role: "संपादक",
      },
      {
        name: "अदिती ओली",
        role: "प्रोजेक्ट एसोसिएट",
      },
      {
        name: "यश चौहान",
        role: "एसोसिएट प्रोडक्ट प्रबंधक",
      },
    ],
  },
  {
    name: "स्टुडिओस्कल",
    role: "सभी संगीत निर्माण स्टुडिओस्कल में",
    people: [
      {
        name: "डैनिएल बी जॉर्ज",
        role: "संगीतकार",
      },
      {
        name: "प्रोतिज्योति घोष",
        role: "संगीत उत्पादन",
      },
      {
        name: "उज्जवल कश्यप",
        role: "संगीत उत्पादन",
      },
      {
        name: "होपुन सैकिया",
        role: "मिक्स एंड मास्टर",
      },
    ],
  },
  {
    name: "विहारा इनोवेशन नेटवर्क ",
    role: "रचनात्मक सामग्री, डिजाइन रणनीति और प्रोटोटाइप परीक्षण।",
    people: [
      {
        name: "नमीता मोहनदास",
        role: "क्रिएटिव लीड",
      },
      {
        name: "अमेया चतुर्वेदी",
        role: "सामग्री प्रबंधक",
      },
      {
        name: "तुषार अभिचंदानी",
        role: "स्क्रिप्ट विशेषज्ञ",
      },
      {
        name: "स्निग्धा झा",
        role: "डिज़ाइन प्रबंधक",
      },
      {
        name: "श्रीविद्या वेंकटरमण",
        role: "डिज़ाइन शोधकर्ता",
      },
      {
        name: "जौसलिन जोसफ",
        role: "डिज़ाइन शोधकर्ता",
      },
      {
        name: "सादिया तबस्सुम",
        role: "डिज़ाइन शोधकर्ता",
      },
      {
        name: "प्रधान थंड्रा",
        role: "कला निर्देशक",
      },
      {
        name: "इंद्रायुध सेनगुप्ता",
        role: "विज़ुअल डिज़ाइनर",
      },
      {
        name: "स्वपनेश समईया",
        role: "खेल डिज़ाइनर",
      },
      {
        name: "स्वाती मिश्रा",
        role: "रिसर्च इंटर्न",
      },
      {
        name: "अभिषेक सोलंकी",
        role: "यू.एक्स इंटर्न",
      },
      {
        name: "अर्पित छिकारा",
        role: "लेखक",
      },
      {
        name: "अवनी तावरगेरी",
        role: "डिजिटल प्रोटोटाइपिंग प्रबंधक ",
      },
      {
        name: "राधिका कन्नन",
        role: "संचालन प्रबंधक",
      },
      {
        name: "कामिनी कुमारी",
        role: "अनुसंधान समन्वयक - अजमेर (महिला जन अधिकार समिति)",
      },
      {
        name: "यशोदा गुर्जर",
        role: "अनुसंधान समन्वयक - अजमेर (महिला जन अधिकार समिति)",
      },
      {
        name: "मीना कामत",
        role: "अनुसंधान समन्वयक - दिल्ली (फेमिनिस्टअप्प्रोच टू टेक्नोलॉजी)",
      },
      {
        name: "पूजा जेपी",
        role: "अनुसंधान समन्वयक - दिल्ली (फेमिनिस्टअप्प्रोच टू टेक्नोलॉजी)",
      },
      {
        name: "सुरभि कुमारी",
        role: "अनुसंधान समन्वयक - पटना (फेमिनिस्टअप्प्रोच टू टेक्नोलॉजी)",
      },
      {
        name: "सुष्मिता मंडल",
        role: "अनुसंधान समन्वयक - पटना (फेमिनिस्टअप्प्रोच टू टेक्नोलॉजी)",
      },
      {
        name: "बबीता कुमारी",
        role: "अनुसंधान समन्वयक - दिल्ली (मातृ सुधा)",
      },
      {
        name: "आशा रानी",
        role: "अनुसंधान समन्वयक - दिल्ली (मातृ सुधा)",
      },
      {
        name: "बेबी हुसैन",
        role: "अनुसंधान समन्वयक - दिल्ली (मातृ सुधा)",
      },
    ],
  },
  {
    name: "मेटामर्सिव",
    role: "गेम डिवेलपमेंट स्टूडियो ",
    people: [
      {
        name: "अशीमा मिसरी",
        role: "निर्माता",
      },
      {
        name: "अलंकृत शंकर",
        role: "लाइन प्रोड्यूसर - गेम और साउंड डिज़ाइन",
      },
      {
        name: "अजिंक्य मानकर",
        role: "लीड गेम डिवेलपर",
      },
      {
        name: "सुजीत कदम",
        role: "वरिष्ठ गेम डिवेलपर",
      },
      {
        name: "स्मित वाघेला",
        role: "गेम डिवेलपर",
      },
      {
        name: "शैलन अरनहा",
        role: "गेम डिवेलपर",
      },
      {
        name: "ऋषि दवे",
        role: "गेम डिवेलपर",
      },
      {
        name: "स्वप्निल मयेकर",
        role: "गेम डिवेलपर",
      },
      {
        name: "रोहित हेमाने",
        role: "गेम डिवेलपर",
      },
      {
        name: "सतबीर सिंह",
        role: "सिस्टम्स आर्किटेक्ट",
      },
      {
        name: "अनोश मास्टर",
        role: "लीड - यूजर इंटरफेस, यूजर एक्सपीरियंस और ग्राफिक डिज़ाइन",
      },
      {
        name: "इलेशा वर्तक",
        role: "यू.आई/यू.एक्स डिज़ाइनर",
      },
      {
        name: "ऋषिकेश लोकारे",
        role: "लीड - 3D एनवायरनमेंट डिज़ाइन",
      },
      {
        name: "सिद्धार्थ चारी",
        role: "3D एनवायरनमेंट डिज़ाइनर",
      },
      {
        name: "शुभम निकम",
        role: "3D एनवायरनमेंट डिज़ाइनर",
      },
      {
        name: "रूबेन दास",
        role: "लीड - कैरेक्टर डिज़ाइन",
      },
      {
        name: "स्वप्निल वैलिंग",
        role: "लीड - एनिमेशन्स",
      },
      {
        name: "सागर पुजारी",
        role: "एनिमेटर",
      },
      {
        name: "विवेक कडवाने",
        role: "एनिमेटर",
      },
      {
        name: "जस्टिन पिंटो",
        role: "परीक्षण और गुणवत्ता आश्वासन",
      },
      {
        name: "अभिषेक वैद्यनाथन",
        role: "लीड - सिस्टम्स बैकएंड",
      },
      {
        name: "रोहित कासी",
        role: "लाइन प्रोड्यूसर",
      },
      {
        name: "साक्षी शिंदे",
        role: "वित्त और लेखा प्रबंधक",
      },
      {
        name: "स्नेहा मोहिते",
        role: "भर्ती और मानव संसाधन प्रबंधक",
      },
      {
        name: "सिद्धार्थ बैनर्जी",
        role: "कार्यकारी निर्माता",
      },
    ],
  },
  {
    name: "नैम ईआर टेक्नोलॉजी, एलएलसी",
    role: "जॉब सिम्युलेटर - मिनी गेम : टीचर, टीचर!",
    people: [
      {
        name: "सैंग नैम",
        role: "गेम डिज़ाइन सलाहकार, लीड गेम डिज़ाइनर",
      },
      {
        name: "छात्र दल - जॉर्ज मेसन विश्वविद्यालय:",
        org: {
          people: [
            {
              name: "नेट गार्नर",
              role: "लीड एनवायरनमेंट और प्रोप आर्टिस्ट",
            },
            {
              name: "कॉनर स्टर्न",
              role: "लीड कैरेक्टर आर्टिस्ट",
            },
            {
              name: "जॉशुआ डीरोसेट",
              role: "लीड प्रोग्रामर",
            },
            {
              name: "केसी डेविस",
              role: "गेम कलाकार",
            },
            {
              name: "किमी ज़ादेगान",
              role: "गेम कलाकार",
            },
            {
              name: "बैंजामिन जॉनसन",
              role: "गेम प्रोग्रामर",
            },
            {
              name: "एलन शेरबा",
              role: "गेम प्रोग्रामर",
            },
            {
              name: "एशर पिंसन",
              role: "गेम प्रोग्रामर",
            },
            {
              name: "पॉल हिनिकर",
              role: "ध्वनि डिज़ाइन/गेम संगीतकार",
            },
          ],
        },
      },
    ],
  },
  {
    name: "गर्ल इफ़ेक्ट",
    role: "रचनात्मक और मनोवैज्ञानिक अनुसंधान",
    people: [
      {
        name: "अपर्णा राज",
        role: "अनुसंधान प्रबंधक",
      },
      {
        name: "श्रद्धा कुलकर्णी",
        role: "प्रोजेक्ट मैनेजर",
      },
      {
        name: "स्नेहा चतुर्वेदी ",
        role: "कंट्री निदेशक, सामग्री लीड",
      },
      {
        name: "वैष्णवी लोटलीकर",
        role: "विपणन स्ट्रैटेजिस्ट",
      },
    ],
  },
  {
    name: "साइकिल टेक्नोलॉजीज",
    role: "साइकिलबीड्स ऐप",
    people: [
      {
        name: "लेस्ली हेयर",
        role: "अनुसंधान और मूल्यांकन लीड, WG",
      },
      {
        name: "एलेव सेज़र-जेकब्स ",
        role: "निदेशक, विपणन",
      },
    ],
  },
  {
    name: "इंफिनिटी इंटरनेट प्राइवेट लिमिटेड",
    role: "आस्कपारो चैटबॉट",
    people: [
      {
        name: "सुभदीप भट्टाचार्य",
        role: "सी.ई.ओ",
      },
      {
        name: "टीना थंकाचान",
        role: "सामग्री लेखक और प्रोजेक्ट मैनेजर",
      },
      {
        name: "योगेश चव्हाण",
        role: "प्रोजेक्ट मैनेजर",
      },
      {
        name: "अनिरुद्ध रॉय चौधुरी ",
        role: "प्रोजेक्ट मैनेजर",
      },
      {
        name: "दर्पण राउत",
        role: "बैकएंड डिवेलपर",
      },
      {
        name: "रॉड्रिक हिलेरियन",
        role: "फ्रंटएन्ड डिवेलपर",
      },
      {
        name: "संजीव सेबेस्टियन",
        role: "बैकएंड डेवलपर",
      },
    ],
  },
  {
    name: "मल्लन लोव लिंटास  ग्रुप (एम.एल.एल.जी )",
    role: "गो निशा गो के लिए लोगो डिजाइन के लिए एम.एल.एल.जी को विशेष अभिस्वीकृति",
  },
  {
    name: "FHI360",
    role: "वित्तीय और अनुसंधान तकनीकी सहायता के लिए स्केल प्रोजेक्ट (R4S) के लिए अनुसंधान के लिए FHI360 को विशेष धन्यवाद",
    people: [
      {
        name: "बारबरा सौव",
        role: "प्रोजेक्ट निदेशक",
      },
      {
        name: "ऑरेली ब्रूनी",
        role: "वैज्ञानिक, वरिष्ठ अनुसंधान प्रमुख",
      },
      {
        name: "एमिली नेमी",
        role: "एसोसिएट साइंटिस्ट, एसोसिएट डायरेक्टर रिसर्च",
      },
    ],
  },
  {
    name: "पापुलेशन कौंसिल कंसल्टिंग प्राइवेट लिमिटेड",
    role: "गेम प्रभाव मूल्यांकन",
    people: [
      {
        name: "निरंजन सागगुरती",
        role: "अनुसंधान मूल्यांकन सलाहकार",
      },
      {
        name: "मधुसूदन  बत्तला",
        role: "प्रिंसिपल अन्वेषक",
      },
      {
        name: "निज़ामुद्दीन खान",
        role: "सह-अन्वेषक",
      },
      {
        name: "अनन्या साहा",
        role: "सह-प्रिंसिपल अन्वेषक",
      },
      {
        name: "अनिल पॉल",
        role: "तकनीकी सलाहकार",
      },
    ],
  },
  {
    name: "रेस्टलेस डिवेलपमेंट",
    role: "यूथ एंगेजमेंट पार्टनर",
    people: [
      {
        name: "फ्रेंक्लिन पॉल",
        role: "स्ट्रेटेजी लीड",
      },
      {
        name: "शगुन  शर्मा",
        role: "कार्यक्रम कार्यान्वयन लीड",
      },
      {
        name: "सौम्या मैथ्यू",
        role: "संचार/अभियान लीड",
      },
    ],
  },
  {
    name: "कानूनी और व्यावसायिक सलाहकार",
    role: "हमारे कानूनी और व्यावसायिक सलाहकारों के लिए विशेष अभिस्वीकृति",
    people: [
      {
        name: "तापसी जौहरी",
        role: "पार्टनर, लेक्स आई.पी कैर, एल.एल.पी",
      },
      {
        name: "एंड्रयू मोहर",
        role: "पार्टनर, मॉरिस, मैनिंग और मार्टिन, एल.एल.पी",
      },
      {
        name: "कैल्ली क्रोल",
        role: "पार्टनर, मॉरिस, मैनिंग और मार्टिन, एल.एल.पी",
      },
      {
        name: "ओल्गा वॉल",
        role: "प्रिंसिपल, एवलॉन, एल.एल.सी",
      },
    ],
  },
  {
    name: "यू.एस.ए.आई.डी",
    role: "विशेष अभिस्वीकृति : इस गेम को अमेरिकी लोगों के उदार समर्थन से यूनाइटेड स्टेट्स एजेंसी फॉर इंटरनेशनल डेवलपमेंट (यू.एस.ए.आई.डी) के माध्यम से संभव बनाया गया है। यह जरूरी नहीं है कि इस वेबसाइट के विषय – वस्तु, यू.एस.ए.आई.डी या यूनाइटेड स्टेट्स सरकार के विचारों को प्रतिबिंबित करे।",
  },
];
