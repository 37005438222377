import React, { useState } from "react";
import Banner from "../components/Banner";
import PartnerCard from "../components/PartnerCard";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Loading from "../components/Loading";
import { useSelector, useDispatch } from "react-redux";
import { doFetchPartners } from "../action/partners";
import openInNewTab from "../utils/OpenInNewTab";
import { Box, Text } from "@chakra-ui/react";
import { Just4YouCard } from "../components/homepage/Just4You";

export default function Partners() {
  const { t, i18n } = useTranslation();

  const [myHealth, setMyHealth] = useState(true);
  const [myCareer, setMyCareer] = useState(true);
  const [relationships, setRelationships] = useState(true);
  const [lifestyles, setLifestyles] = useState(true);

  const partners = useSelector((state) => state.topics.partners);
  const isPartnersLoading = useSelector(
    (state) => state.topics.isPartnersLoading
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (partners.length === 0 && !isPartnersLoading) {
      doFetchPartners(dispatch);
    }
  }, [isPartnersLoading, partners]);

  if (partners.length === 0 && !isPartnersLoading) {
    return <Loading />;
  }

  const filteredPartners = partners.filter((part) => {
    if (myHealth) {
      if (part?.categories.includes(2)) {
        return true;
      }
    }

    if (myCareer) {
      if (part?.categories.includes(3)) {
        return true;
      }
    }

    if (relationships) {
      if (part?.categories.includes(4)) {
        return true;
      }
    }

    if (lifestyles) {
      if (part?.categories.includes(5)) {
        return true;
      }
    }

    return false;
  });

  return (
    <div className="categorypage">
      <Banner />

      <div className="container">
        <div className="content">
          <div
            className="content__title"
            style={{ marginTop: "36px", marginBottom: "40px" }}
          >
            <Text fontSize="30px" fontFamily="heading">
              {t("COMMON.JUST4YOU")}
            </Text>
          </div>
          <div className="content__para">
            <Text fontSize="18px" fontFamily="body" mb="10px">
              {t("PARTNERSPAGE.DESCRIPTION_1")}
            </Text>
            <Text fontSize="18px" fontFamily="body" mb="60px">
              {t("PARTNERSPAGE.DESCRIPTION_2")}
            </Text>
          </div>
        </div>
      </div>

      <Box
        paddingY="40px"
        backgroundImage="url('assets/img/Just4You.png')"
        backgroundPosition="center center"
        backgroundSize="cover"
      >
        <div className="container">
          <div
            className="content"
            style={{ paddingTop: "20px", paddingBottom: "0px" }}
          >
            {/* <div className="row partners_page_checkbox"> */}
            <div
              className="content__wrapper"
              style={{ justifyContent: "center", textAlign: "center", paddingBottom: "0px" }}
            >
              <div className="just4checkbox">
                <div class="form-check">
                  <label class="form-check-label">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      checked={myHealth}
                      onChange={(e) => setMyHealth((myHealth) => !myHealth)}
                    />
                    <Text
                      fontSize="22px"
                      fontFamily="body"
                      marginTop="-4px"
                      fontWeight="bold"
                    >
                      {t("COMMON.MY_HEALTH")}
                    </Text>
                  </label>
                </div>
              </div>

              <div className="just4checkbox">
                <div class="form-check">
                  <label class="form-check-label">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      checked={myCareer}
                      onChange={(e) => setMyCareer((myCareer) => !myCareer)}
                    />
                    <Text
                      fontSize="22px"
                      fontFamily="body"
                      marginTop="-4px"
                      fontWeight="bold"
                    >
                      {t("COMMON.MY_CAREER")}
                    </Text>
                  </label>
                </div>
              </div>

              <div className="just4checkbox">
                <div class="form-check">
                  <label class="form-check-label">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      checked={lifestyles}
                      onChange={(e) => setLifestyles((lifestyles) => !lifestyles)}
                      />
                    <Text
                      fontSize="22px"
                      fontFamily="body"
                      marginTop="-4px"
                      fontWeight="bold"
                      >
                      {t("COMMON.LIFESTYLES")}
                    </Text>
                  </label>
                </div>
              </div>

              <div className="just4checkbox">
                <div class="form-check">
                  <label class="form-check-label">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      checked={relationships}
                      onChange={(e) =>
                        setRelationships((relationships) => !relationships)
                      }
                      />
                    <Text
                      fontSize="22px"
                      fontFamily="body"
                      marginTop="-4px"
                      fontWeight="bold"
                      >
                      {t("COMMON.RELATIONSHIPS")}
                    </Text>
                  </label>
                </div>
              </div>
            </div>

            <div
              className="content__wrapper"
              style={{ justifyContent: "center", textAlign: "center", paddingBottom: "0px" }}
            >
              {filteredPartners.map((partner) => {
                return <Just4YouCard className="just4card" key={partner.id} {...partner} />;
              })}

              {filteredPartners.length == 0 && !isPartnersLoading ? (
                <Text
                fontSize="22px"
                fontFamily="body"
                marginTop="32px"
                color="#FFF"
                fontWeight="bold"
              >
                {t("COMMON.NO_PARTNER_FOUND")}
              </Text>
              ) : null}
            </div>
          </div>
        </div>
      </Box>

      <p className="text-center">
        <a
          target="_blank"
          onClick={() => openInNewTab(t("COMMON.CLICK_HERE_TO_PARTNER_LINK"))}
          className="btn-lg partner-btn"
          style={{
            borderRadius: "36px",
            fontSize: "22px",
            marginTop: "60px",
            marginBottom: "60px",
            cursor: "pointer"
          }}
        >
          {t("COMMON.CLICK_HERE_TO_PARTNER")}
        </a>
      </p>
    </div>
  );
}
