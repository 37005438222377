import React from "react";
import { Flex, Text, Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  return (
    <section className="404" style={{ display: "flex", justifyContent: "center", height: 600 }}>
      <div style={{ display: "block", textAlign: "center" }}>
        <img
          src={window.location.origin + "/assets/img/404.png"}
          style={{ width: 800, marginBottom: 30 }}
          alt=""
          srcSet=""
        />
        <p style={{ fontFamily: "palanquin_darkregular", fontSize: "18pt", textAlign: "center" }}>
          OOPS! PAGE NOT FOUND :(
        </p>
        <p style={{ fontFamily: "Roboto", fontSize: "18pt", textAlign: "center" }}>
          The page you are looking for does not exist.
        </p>
        <Button
          size="sm"
          colorScheme="white"
          border="2px solid"
          bg="#E85900"
          borderRadius="30px"
          color="white"
          width="240px"
          height="48px"
          marginTop="30px"
          onClick={(e) => {
            navigate("/");
          }}>
          <Text fontFamily="palanquin_darkregular" fontWeight="bold" fontSize="16px">
            GO TO HOME PAGE
          </Text>
        </Button>
      </div>
    </section>
  );
}
