import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { MY_HEALTH_SLUG, MY_CAREER_SLUG, RELATIONSHIP_SLUG, LIFESTYLE_SLUG } from "../../constants";
import {
  Flex,
  Box,
  Image,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Divider,
} from "@chakra-ui/react";
import { AiOutlineClose } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import { doFetchCategories, doFetchTopics } from "../../action/topics";
import Loading from "../Loading";

export default function TopicCards() {
  const { t, i18n } = useTranslation();
  const [selectedTopic, setSelectedTopic] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const categories = useSelector((state) => state.topics.categories);
  const isCategoriesLoading = useSelector((state) => state.topics.isCategoriesLoading);
  const topics = useSelector((state) => state.topics.topics);

  const isPartnersLoading = useSelector((state) => state.topics.isPartnersLoading);
  const isTopicsLoading = useSelector((state) => state.topics.isTopicsLoading);
  const dispatch = useDispatch();

  React.useEffect(() => {
    doFetchCategories(dispatch);
  }, []);

  React.useEffect(() => {
    if (categories && categories.length === 0 && !isTopicsLoading) {
      doFetchTopics(dispatch);
    }
  }, [categories]);

  if (!categories || !categories.length || !topics || !topics.length) return <Loading />;

  const topicsBySlug = (slug) => {
    let tpcs = [];

    let cat = categories.find((category) => category.slug == slug);

    if (cat) {
      tpcs = topics.filter((tpc) => tpc.categories.includes(cat.id));
    }

    return tpcs;
  };

  const topicArrays = [
    {
      title: "COMMON.MY_HEALTH",
      color: "#008CF0",
      img: "Icon_MyHealth.png",
      description: "HOMEPAGE.MY_HEALTH_EXCERPT",
      slug: MY_HEALTH_SLUG,
      topics: topicsBySlug(MY_HEALTH_SLUG),
    },
    {
      title: "COMMON.MY_CAREER",
      color: "#1BAB50",
      img: "Icon_MyCareer.png",
      description: "HOMEPAGE.MY_CAREER_EXCERPT",
      slug: MY_CAREER_SLUG,
      topics: topicsBySlug(MY_CAREER_SLUG),
    },
    {
      title: "COMMON.RELATIONSHIPS",
      color: "#E62861",
      img: "Icon_Relationships.png",
      description: "HOMEPAGE.RELATIONSHIPS_EXCERPT",
      slug: RELATIONSHIP_SLUG,
      topics: topicsBySlug(RELATIONSHIP_SLUG),
    },
    {
      title: "COMMON.LIFESTYLES",
      color: "#9C1EE5",
      img: "Icon_Lifestyle.png",
      description: "HOMEPAGE.LIFESTYLES_EXCERPT",
      slug: LIFESTYLE_SLUG,
      topics: topicsBySlug(LIFESTYLE_SLUG),
    },
  ];

  const handleViewTopics = (topic) => {
    setSelectedTopic(topic);
    onOpen();
  };

  return (
    <>
      <section className="category">
        <Box width="100%" maxWidth="1170px" mx="auto" px={4}>
          <Flex
            direction={["column", "column", "row", "row"]}
            justify={["center", "center", "space-evenly", "space-evenly"]}
            py={"30px"}
            wrap="wrap"
            className="topicCard">
            {topicArrays.map((tp, index) => (
              <TopicCardBlock key={`${tp.title}-${index}`} {...tp} onViewTopics={handleViewTopics} />
            ))}
          </Flex>
        </Box>
      </section>

      <TopicCardModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} selectedTopic={selectedTopic} />
    </>
  );
}

const TopicCardBlock = (props) => {
  const { t, i18n } = useTranslation();

  const { title, description, color, img, onViewTopics, slug } = props;

  const navigate = useNavigate();

  const goTo = (url) => {
    navigate(url);
  };

  return (
    <Flex
      bg={color}
      position="relative"
      my={"30px"}
      shadow="lg"
      p="20px"
      width="100%"
      maxWidth="400px"
      borderRadius="30px"
      color="white"
      direction="column"
      mx={["auto", "auto", 0, 0]}
      onClick={(e) => {
        goTo(slug);
      }}
      cursor="pointer"
      boxShadow="0 0 20px 6px rgb(0 0 0 / 30%)">
      <Box ml="auto" height="200px" maxHeight="200px" mt="-20px" mr="-20px">
        <Image src={`assets/img/icons/${img}`} width="200px" height="200px" />
      </Box>

      <Text width="100%" fontFamily="palanquin_darkregular" fontSize="30px" mb={1} mt="-51px">
        {t(title)}
      </Text>

      <Text fontFamily="body" fontWeight="bold">
        {t(description)}
      </Text>

      <Box mt={2}>
        <Button
          size="sm"
          colorScheme="white"
          border="2px solid"
          bg="white"
          borderRadius="16px"
          color="#e85900"
          className="whover"
          onClick={(e) => {
            e.stopPropagation();
            onViewTopics(props);
          }}>
          <Text fontFamily="body" fontWeight="bold" fontSize="16px">
            {t("HOMEPAGE.VIEW_TOP_ARTICLES")}
          </Text>
        </Button>
      </Box>
    </Flex>
  );
};

const TopicCardModal = ({ isOpen, onClose, selectedTopic }) => {
  // const { title, description, color, img } = selectedTopic;

  const { t, i18n } = useTranslation();

  if (!selectedTopic) return <> </>;

  const selectedCategoryTopics =
    selectedTopic.topics
      .reverse()
      .filter((tp) => {
        // console.log(tp);
        return tp?.show_on_homepage;
      })
      .slice(0, 3) || [];

  return selectedTopic ? (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
        <ModalOverlay />
        <ModalContent m={4} borderRadius="30px" overflow="hidden" maxWidth="400px">
          <ModalHeader color="white" bg={selectedTopic.color || "red.500"} py={0}>
            <Flex direction="row" align="center" justify="space-between" minHeight="90px">
              <Text ml="0px" fontSize="30px" fontFamily="palanquin_darkregular" fontWeight="400">
                {t(selectedTopic.title || "")}
              </Text>

              <Flex
                align="center"
                justify="center"
                borderRadius="50%"
                width="30px"
                height="30px"
                backgroundColor="rgba(0, 0, 0, 0.3);"
                color="white"
                onClick={onClose}
                cursor="pointer">
                <Box as={AiOutlineClose} color="white" />
              </Flex>
            </Flex>
          </ModalHeader>
          <ModalBody px={"20px"} position="relative" py={2}>
            <Box position="absolute" opacity="0.2" top="0%" right="0%" zIndex={1}>
              <Image src={`assets/img/icons/${selectedTopic.img}`} width="200px" height="200px" zIndex={1} />
            </Box>

            {selectedCategoryTopics && selectedCategoryTopics.length ? (
              <Flex position="relative" direction="column" zIndex={10} pointerEvents="all" outline="0">
                {selectedCategoryTopics.map((tp, index) => (
                  <>
                    <Link to={`${selectedTopic?.slug}/${tp.slug}`}>
                      <Flex m="0" minHeight="70px" align="center" className="cat_topic" outline="0">
                        <Text fontSize="18px" color="#5C5C5C" fontFamily="body">
                          {i18n.language === "en" ? tp.title : tp.title_in_hindi}
                        </Text>
                      </Flex>
                    </Link>
                    {selectedCategoryTopics.length == index + 1 ? "" : <Divider my={0} color="#E85900" />}
                  </>
                ))}
              </Flex>
            ) : (
              <Box p={4} px={0} my={8} textAlign="left">
                <Text color="#5C5C5C" fontSize="16px" fontWeight="bold" fontFamily="body">
                  {t("HOMEPAGE.NO_TOPICS_EXISTS")}
                </Text>
              </Box>
            )}
          </ModalBody>
          <ModalFooter px={"20px"} bg="#f0f0f0">
            <Box textAlign="left" mr="auto">
              <Text fontSize="16px" color="#5C5C5C" fontFamily="body" mb={1}>
                {t("HOMEPAGE.CLICK_ON_ANY")}
              </Text>

              <Link to={selectedTopic.slug + "?topic"}>
                <Button
                  size="md"
                  colorScheme="white"
                  border="2px solid"
                  bg="#e85900"
                  borderRadius="32px"
                  color="white"
                  className="ohover cat-btn"
                  outline="0">
                  {t("HOMEPAGE.CLICK_HERE_TO_SEE")}
                </Button>
              </Link>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  ) : (
    ""
  );
};
