import React from "react";
import Banner from "../components/Banner";
import PartnerCard from "../components/PartnerCard";
import { useTranslation } from "react-i18next";
import { useParams, Link } from "react-router-dom";
import Loading from "../components/Loading";
import NotFound from "./NotFound";
import { useSelector, useDispatch } from "react-redux";
import { doFetchPartners } from "../action/partners";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import openInNewTab from "../utils/OpenInNewTab";
import Just4You from "../components/homepage/Just4You";
import { Box, Flex, Image, Text } from "@chakra-ui/react";

export default function PartnersDetails() {
  const { t, i18n } = useTranslation();
  const { partnerSlug } = useParams();

  const partners = useSelector((state) => state.topics.partners);
  const isPartnersLoading = useSelector((state) => state.topics.isPartnersLoading);
  const isPartnersFetched = useSelector((state) => state.topics.isPartnersFetched);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (partners.length === 0 && !isPartnersLoading) {
      doFetchPartners(dispatch);
    }
  }, [isPartnersLoading, partners]);

  const partner = partners.find((p) => p.slug === partnerSlug);

  if (!partner) {
    return !isPartnersFetched ? <Loading /> : <NotFound />;
  }

  // console.log(partner);

  return (
    <div class="topicpage">
      <Banner />

      <div class="container">
        <div class="content">
          <br />

          <Text fontFamily="heading" fontSize={["24px", "26px", "28px", "30px"]} mb={[4, 4, 6, 8]} color="#e85900">
            {i18n.language === "en" ? partner.title : partner.partner_title_in_hindi}
          </Text>

          <ContentBox
            englishContent={partner.partner_details_in_english ? partner.partner_details_in_english : ""}
            hindiContent={partner.partner_details_in_hindi ? partner.partner_details_in_hindi : ""}
            imageURL={partner.partners_page_main_image}
          />

          <br />
          <br />

          {partner.show_brand ? (
            <>
              <ContentBox
                englishContent={partner.brand_description ? partner.brand_description : ""}
                hindiContent={partner.brand_description_in_hindi ? partner.brand_description_in_hindi : ""}
                imageURL={partner?.brand_image?.url ? partner.brand_image.url : null}
              />
              <br />
              <br />
            </>
          ) : (
            <> </>
          )}

          {partner.show_entreprenuer ? (
            <>
              <ContentBox
                englishContent={partner.entreprenuer_discussion ? partner.entreprenuer_discussion : ""}
                hindiContent={partner.entreprenuer_discussion_in_hindi ? partner.entreprenuer_discussion_in_hindi : ""}
                imageURL={partner?.entreprenuer_image?.url ? partner.entreprenuer_image.url : null}
              />
              <br />
              <br />
            </>
          ) : (
            <> </>
          )}
        </div>
      </div>

      <Box mb="60px"></Box>

      <Just4You />
    </div>
  );
}

const ContentBox = ({ englishContent, hindiContent, imageURL }) => {
  const { t, i18n } = useTranslation();

  return (
    <Flex direction={["column", "column", "row", "row"]} justify="space-between">
      <Box width={["100%", "100%", "65%", "65%"]} px={0} py={2} mb={4}>
        {ReactHtmlParser(i18n.language === "en" ? englishContent : hindiContent)}
      </Box>

      {imageURL ? (
        <Box width={["100%", "100%", "30%", "30%"]} mb={4} p={4}>
          <Image src={imageURL} m="auto" />
        </Box>
      ) : (
        <> </>
      )}
    </Flex>
  );
};
