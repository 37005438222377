import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";
import { Text, Box } from "@chakra-ui/react";
import Slider from "react-slick";
import { ArrowSlide } from "./meet-memebers";
import { HDI_API, GetMainPageStats } from "../../api";
import { formatNumToInr } from "../../helper/formatTopics";

export default function Metrics() {
  const { t, i18n } = useTranslation();

  return (
    <section className="metric" style={{ paddingTop: "16px" }}>
      <div className="container carousel">
        <h3 className="metric__title">
          <Text
            fontFamily="heading"
            textAlign="center"
            // fontSize={["26px", "28px", "30px", "32px"]}
            fontSize="30px"
          >
            {t("HOMEPAGE.SEE_HOW_MANY_PLAYING")}
          </Text>
        </h3>
        <MetricSlider />

        <Box mb="60px"></Box>
        <hr />
      </div>
    </section>
  );
}

export const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  afterChange: (e) => {},
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 750,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
  ],
  nextArrow: <ArrowSlide type="next" color="gray.400" />,
  prevArrow: <ArrowSlide color="gray.400" />,
};

const MetricSlider = () => {
  const { t, i18n } = useTranslation();

  const [HpCount, setHpCount] = useState([]);
  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    HDI_API.get(GetMainPageStats)
      .then((res) => {

        if (res && res.data && Object.keys(res.data).length) {

            let hpCnt = {};
            Object.keys(res.data).map((k) => {
              hpCnt[k] = formatNumToInr(res.data[k]);
            });

            if (hpCnt) {
              setHpCount(hpCnt);
              setisLoading(false);
            }
        }
      })
      .catch((err) => console.log(err));
  }, []);

  if (isLoading) return <> </>;

  return (
    <Slider {...settings} className="metricSlider">
      <div className="metric__box">
        <div
          className="metric__box-count text-orange"
          style={{ color: "#E85900" }}
        >
          {/* {ReactHtmlParser(t("HOMEPAGE.NUMBER_LIFE_CHOICES_MADE"))}{" "} */}
          {ReactHtmlParser(HpCount.choices)}{" "}
        </div>
        <div className="metric__box-title">
          {ReactHtmlParser(t("HOMEPAGE.LIFE_CHOICES_MADE"))}
        </div>
      </div>

      <div className="metric__box">
        <div
          className="metric__box-count text-pink"
          style={{ color: "#9C1EE5" }}
        >
          {/* {ReactHtmlParser(t("HOMEPAGE.NUMBER_TOTAL_PLAYER_TIME"))}{" "} */}
          {ReactHtmlParser(HpCount.plyGmTime)}{" "}
        </div>
        <div className="metric__box-title">
          {ReactHtmlParser(t("HOMEPAGE.TOTAL_PLAYER_TIME"))}
        </div>
      </div>

      <div className="metric__box">
        <div
          className="metric__box-count text-blue"
          style={{ color: "#008CF0" }}
        >
          {/* {ReactHtmlParser(t("HOMEPAGE.NUMBER_GAME_SESSION_PLAYED"))}{" "} */}
          {ReactHtmlParser(HpCount.sess)}{" "}
        </div>
        <div className="metric__box-title">
          {ReactHtmlParser(t("HOMEPAGE.GAME_SESSION_PLAYED"))}
        </div>
      </div>
      <div className="metric__box">
        <div
          className="metric__box-count text-orange"
          style={{ color: "#E85900" }}
        >
          {/* {ReactHtmlParser(t("HOMEPAGE.NUMBER_LIFE_CHOICES_MADE"))}{" "} */}
          {ReactHtmlParser(HpCount.choices)}{" "}
        </div>
        <div className="metric__box-title">
          {ReactHtmlParser(t("HOMEPAGE.LIFE_CHOICES_MADE"))}
        </div>
      </div>

      <div className="metric__box">
        <div
          className="metric__box-count text-pink"
          style={{ color: "#9C1EE5" }}
        >
          {/* {ReactHtmlParser(t("HOMEPAGE.NUMBER_TOTAL_PLAYER_TIME"))}{" "} */}
          {ReactHtmlParser(HpCount.plyGmTime)}{" "}
        </div>
        <div className="metric__box-title">
          {ReactHtmlParser(t("HOMEPAGE.TOTAL_PLAYER_TIME"))}
        </div>
      </div>

      <div className="metric__box">
        <div
          className="metric__box-count text-blue"
          style={{ color: "#008CF0" }}
        >
          {/* {ReactHtmlParser(t("HOMEPAGE.NUMBER_GAME_SESSION_PLAYED"))}{" "} */}
          {ReactHtmlParser(HpCount.sess)}{" "}
        </div>
        <div className="metric__box-title">
          {ReactHtmlParser(t("HOMEPAGE.GAME_SESSION_PLAYED"))}
        </div>
      </div>
    </Slider>
  );
};
