import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { doFetchPartners } from "../../action/partners";
import SwiperCore, { Navigation } from "swiper";
import { Box, Text, Flex, Button } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { ArrowSlide } from "./meet-memebers";

// install Virtual module
SwiperCore.use([Navigation]);

export const settings = (leng) => {
  return {
    dots: false,
    infinite: leng && leng > 3 ? true : false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    afterChange: (e) => {},
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          infinite: leng && leng > 3 ? true : false,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: leng && leng > 3 ? true : false,
        },
      },
    ],
    nextArrow: <ArrowSlide type="next" color="gray.100" />,
    prevArrow: <ArrowSlide color="gray.100" />,
  };
};

export default function Just4You(props) {
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  const { t, i18n } = useTranslation();
  let partners = useSelector((state) => state.topics.partners);
  const isPartnersLoading = useSelector((state) => state.topics.isPartnersLoading);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (partners.length === 0 && !isPartnersLoading) {
      doFetchPartners(dispatch);
    }
  }, [isPartnersLoading, partners]);

  if (props.partners && props.partners.length) {
    partners = props.partners;
  }

  return (
    <Box
      marginY="-2px"
      backgroundImage="url('/assets/img/Just4You.png')"
      backgroundPosition="center center"
      backgroundSize="cover">
      <section className="partner">
        <div className="container carousel">
          <h3 className="partner__title">
            <Text fontFamily="heading" textAlign="center" fontSize="30px" color="#aa2b00">
              {t("COMMON.JUST4YOU")}
            </Text>
          </h3>

          <Box paddingBottom="8px"></Box>

          {partners.length > 0 ? (
            <>
              <Box
                className="partner__carousel just4you"
                style={{ paddingTop: "0px", paddingBottom: "0px" }}
                width="100%">
                <Slider {...settings(partners.length)}>
                  {partners.map((p, index) => {
                    return <Just4YouCard className="just4cardslide" key={p.id} {...p} />;
                  })}
                </Slider>
              </Box>
            </>
          ) : (
            <> </>
          )}

          <p className="text-center">
            <Link to="/just4you">
              <Button
                width="126px"
                height="50px"
                borderRadius="25px"
                style={{ borderRadius: "25px" }}
                bg="white"
                border="1px solid #E85900"
                fontFamily="heading"
                fontSize="26px"
                color="#E85900"
                textTransform="uppercase"
                fontWeight="400">
                {t("COMMON.SEE_ALL")}
              </Button>
            </Link>
          </p>
        </div>
      </section>
    </Box>
  );
}

export const Just4YouCard = (props) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const goToNext = (url) => {
    navigate(url);
  };

  // console.log(props?.partner_homepage_image?.url);

  return (
    <Box
      mx={["0", "0", "auto", "auto"]}
      my={["30px"]}
      onClick={() => goToNext(`/just4you/${props.slug}`)}
      position="relative"
      width="100%"
      // minWidth={["100%", "300px", "300px", "300px"]}
      maxWidth="300px"
      height="260px"
      borderRadius="30px"
      bg="gray.50"
      overflow="hidden"
      backgroundSize="cover"
      backgroundPosition="center center"
      backgroundImage={`url(${props.partner_homepage_image?.url})`}
      cursor="pointer"
      boxShadow="lg"
      className={props.className}
      boxShadow="0 0 20px 6px rgb(0 0 0 / 20%)">
      <Flex
        justify="left"
        width="100%"
        //height="90px"
        // backgroundColor="rgba(0,0,0,0.7)"
        background="linear-gradient(to top, #000000c9, transparent)"
        position="absolute"
        bottom="0"
        align="end"
        p={6}>
        <Text fontFamily="heading" color="white" fontSize="30px" textAlign="left" lineHeight="1.3">
          {i18n.language == "en" ? props.title : props.partner_title_in_hindi}
        </Text>
      </Flex>
    </Box>
  );
};
