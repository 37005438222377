  import axios from 'axios';
import { BASE_URL, API_BASE_URL } from './constants'

export default axios.create({
  baseURL: BASE_URL
});

export const HDI_API = axios.create({
  baseURL: API_BASE_URL
});

export const GetMainPageStats = "/GetMainPageStats";

export const SetRateGame = "/SetRateGame/";

export const SetRateTopic = "/SetRateTopic/";

export const SetFAQ = "/SetFAQ/";

export const HEADERS = {
  "Content-Type": "multipart/form-data"
}