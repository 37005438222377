// CATEGORIES
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const FETCH_CATEGORIES_COMPLETE = 'FETCH_CATEGORIES_COMPLETE';

export const FETCH_TOPIC = 'FETCH_TOPIC';
export const FETCH_TOPIC_COMPLETE = 'FETCH_TOPIC_COMPLETE';

export const FETCH_PARTNERS = 'FETCH_PARTNERS';
export const FETCH_PARTNERS_COMPLETE = 'FETCH_PARTNERS_COMPLETE';

export const FETCH_NEWS = 'FETCH_NEWS';
export const FETCH_NEWS_COMPLETE = 'FETCH_NEWS_COMPLETE';