import { extendTheme, theme } from "@chakra-ui/react";

const ctheme = extendTheme({
  fonts: {
    body: `"Roboto", sans-serif`,
    heading: `palanquin_darkregular`,
    mono: `"Roboto", sans-serif`,
  },
});

export default ctheme;
