import React from "react";
import Banner from "../components/Banner";
import NewsCard from "../components/NewsCard";
import { useTranslation } from 'react-i18next';
import Loading from "../components/Loading";
import { useSelector, useDispatch } from 'react-redux';
import { doFetchNews } from '../action/partners';
import API from '../api';
import TopicCard from "../components/TopicCard";
// import PartnerCard from "../components/PartnerCard";
import { Just4YouCard } from "../components/homepage/Just4You";

export default function Search() {
    
    const { t, i18n } = useTranslation();

    const news = useSelector(state => state.topics.news)
    const isNewsLoading = useSelector(state => state.topics.isNewsLoading);
    const [query, setQuery] = React.useState('')
    const [isSearching, setIsSearching] = React.useState(false);
    const [postArray, setPosts] = React.useState([]);
    const [newsArray, setNews] = React.useState([]);
    const [partnersArray, setPartners] = React.useState([]);
    const [isSearched,setIsSearched] = React.useState(false);


    const handleKeypress = e => {
      if (e.keyCode === 13) {
        searchPosts();
      }
    }

    const searchPosts = async () => {

      setPosts([]);
      setPartners([]);
      setNews([]);
      setIsSearching(true);
      setIsSearched(true);


       try {
        let response = await API.get(`/search?search=${query}&_embed&per_page=100`)
        
        let posts = [];
        let news = [];
        let partners = [];

        if(response.data && response.data.length  > 0){
          response.data.map(p => {
            
            let body;

            if(p.subtype && p.subtype == 'topics'){
              body = p['_embedded']['self'][0];
              
              posts.push({
                title : p.title, 
                title_in_hindi : body.acf.title_in_hindi,
                excerpt_in_english : body.acf.excerpt_in_english, 
                excerpt_in_hindi : body.acf.excerpt_in_english, 
                category : "",
                id : p.id, 
                slug : body.slug,
                thumbnail: body?.acf?.thumbnail?.url || "",
              })
            } else if(p.subtype && p.subtype == 'partners'){
              
              body = p['_embedded']['self'][0];

              // console.log(p, body);

              let pa = {
                id: p.title,
                slug : body.slug,
                title : p.title,
                partner_homepage_image : body.acf.partner_homepage_image, 
                partner_title_in_hindi : body.acf.partner_title_in_hindi, 
                excerpt_in_english : body.acf.excerpt_in_english, 
                excerpt_in_hindi : body.acf.excerpt_in_hindi
              };

              // console.log(body, pa);
              partners.push(pa);


            } else if(p.subtype && p.subtype == 'news'){
              body = p['_embedded']['self'][0];

              news.push({
                 id: p.id,
                 external_url: body.acf.external_url,
                 title : p.title,
                 news_title_in_hindi :  body.acf.news_title_in_hindi,
                 excerpt_in_english :  body.acf.excerpt_in_english,
                 excerpt_in_hindi :  body.acf.excerpt_in_hindi,
                 thumbnail: body?.acf?.thumbnail?.url || "",
              })
            }
          })

         setPosts(posts);
         setNews(news);
         setPartners(partners);
        }
       } catch (error) {
          console.log(error)
       }
       setIsSearching(false);
    }
  
    return (
    <div class="categorypage searchpage">
      <Banner />

      <div class="container">
        <div class="content pb-0 searchContent">
          <div class="content__title mb-0" style={{textTransform: "uppercase", fontSize:"30px"}}> { t("SEARCHPAGE.SEARCH") } </div>
        </div>

        <div className="content search-wrapper">
          <input 
            placeholder={ t("SEARCHPAGE.ENTER_YOUR_QUERY") } 
            value={query} 
            onChange={e => setQuery(e.target.value)}
            onKeyDown={handleKeypress}
          />

          <button 
            className="partner-btn" 
            onClick={searchPosts} 
            onKeyPress={handleKeypress} 
          > 
          { t("SEARCHPAGE.SEARCH") } 
          </button>
        </div>


        {
          isSearching ?
          (
            <div class="d-flex justify-content-center align-items-center" style={{paddingTop:"40px", paddingBottom:"100px"}}>
              <div class="spinner-border search-spinner" role="status">
                <span class="sr-only"></span>
              </div>
              &nbsp;  &nbsp;
              <div
                className="content__title mb-0"
              >
                { t("SEARCHPAGE.SEARCHING") }....
              </div>
            </div>
          ): (
            <>
            {
              ((postArray.length === 0) &&  (partnersArray.length === 0) && (newsArray.length === 0) &&  !isSearching && isSearched) ?   <div class="content">

              <div class="content__wrapper pt-0 text-center" style={{justifyContent: 'center'}} >
              { t("SEARCHPAGE.NO_RESULTS") }
              </div>
              </div> : ""
            }
            {postArray.length > 0 ? (
              <div class="content">
                <div className="content__title text-grey"
                      style={{ color: "#4a4a4a" }}>  { t("COMMON.TOPICS") } </div>
                <div class="content__wrapper pt-0">
                  {
                    postArray.map(nw => <TopicCard key={nw.id} {...nw} /> )
                  }
                </div>
              </div>
            ) : ''}

        
        {partnersArray.length > 0 ? 
        <div class="content">
            <div
              className="content__title text-grey"
              style={{ color: "#4a4a4a" }}>
            { t("COMMON.PARTNERS") }
            </div>
          <div class="content__wrapper pt-0">
              {
                  partnersArray.map(nw => <Just4YouCard key={nw.id} {...nw} /> )
                  // partnersArray.map(nw => <PartnerCard key={nw.id} {...nw} /> )
              }
          </div>
        </div> : '' }


        {newsArray.length > 0 ?
        <div class="content">
            <div
              className="content__title text-grey"
              style={{ color: "#4a4a4a" }}>
              { t("COMMON.IN_THE_NEWS") }
            </div>
          <div class="content__wrapper pt-0">
              {
                  newsArray.map(nw => <NewsCard key={nw.id} {...nw} /> )
              }
          </div>
        </div> : ''}

            </>
          )
        }

       

      </div>
    </div>
  );
}
