import React from "react";
import Banner from "../components/Banner";
import { useTranslation } from "react-i18next";
import API, { SetFAQ } from "../api";
import Faq from "../components/faq";

export default function Faqs() {
  const { t, i18n } = useTranslation();
  const [mode, setMode] = React.useState("faq"); //faq | faq-parent
  const [faqs, setFaqs] = React.useState([]);
  const [faqp, setFaqParents] = React.useState([]);

  React.useEffect(() => {
    fetchFAQsParents();
    fetchFAQs();
  }, []);

  const fetchFAQs = async () => {
    try {
      let fqs = [];

      let res = await API.get("/faqs?per_page=100");

      if (res.data && res.data.length > 0) {
        fqs = res.data.map((f) => {
          return { fid: f.id, url: SetFAQ, ...f.acf };
        });
        setFaqs(fqs);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchFAQsParents = async () => {
    try {
      let faqp = [];

      let res = await API.get("/faqp?per_page=100");

      if (res.data && res.data.length > 0) {
        faqp = res.data.map((q) => {
          return { id: q.id, url: SetFAQ, ...q.acf };
        });
        setFaqParents(faqp);
      }
    } catch (error) {
      console.log(error);
    }
  };

  var ures = {};
  if (sessionStorage.getItem("ures") !== null) {
    ures = JSON.parse(sessionStorage.getItem("ures"));
  }

  return (
    <div class="categorypage supportpage">
      <Banner />

      <div className="container mb-4">
        <div className="content pb-0">
          <ul className={`supportpage__tab-nav ${mode === "faq-parent" ? "supportpage__tab-nav--faqs" : ""} `}>
            <li onClick={() => setMode("faq")}> {t("SUPPORTPAGE.FAQ_PLAYER")} </li>
            <li onClick={() => setMode("faq-parent")}> {t("SUPPORTPAGE.FAQ_PARENTS")} </li>
          </ul>

          {mode === "faq" ? (
            <>
              <div className="faqs" style={{ paddingTop: "40px", paddingBottom: "40px" }}>
                <div class="content__title text-blue mb-0" style={{ fontSize: "30px" }}>
                  {" "}
                  {t("SUPPORTPAGE.FAQ")}{" "}
                </div>
              </div>
              {faqs.map((fq) => (
                <Faq classes={"pinkColor"} ures={ures} key={fq.id} {...fq} />
              ))}
            </>
          ) : (
            <>
              <div className="rate-our-game" style={{ paddingTop: "40px", paddingBottom: "40px" }}>
                <div class="content__title text-blue mb-0" style={{ fontSize: "30px" }}>
                  {" "}
                  {t("SUPPORTPAGE.FAQ")}{" "}
                </div>
              </div>
              {faqp.map((fq) => (
                <Faq classes={"blueColor"} ures={ures} key={fq.id} {...fq} />
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
