import React from "react";
import { useTranslation } from "react-i18next";
import { Text } from "@chakra-ui/react";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import { HDI_API, HEADERS } from "../api";

export default function Faq({
  question,
  question_in_hindi,
  answer,
  answer_in_hindi,
  image_1,
  image_2,
  bug,
  bug_options,
  image_1_caption,
  image_1_caption_in_hindi,
  image_2_caption,
  image_2_caption_in_hindi,
  fid,
  url,
  ures,
  classes,
}) {
  const [isOpen, setIsOpen] = React.useState(false);
  const { t, i18n } = useTranslation();

  const [isAns, setIsAns] = React.useState(null);
  const [isSent, setIsSent] = React.useState(false);

  const handleSubmit = () => {
    if (!isAns) {
      return;
    }

    setIsSent(true);
    sendData();
  };

  const sendData = () => {
    let pstdt = new FormData();
    pstdt.append("optId", isAns);
    HDI_API.post(url + fid, pstdt, { headers: HEADERS })
      .then((res) => {
        ures[fid] = isAns;
        console.debug(ures);
        sessionStorage.setItem("ures", JSON.stringify(ures));
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className={`faq ${isOpen ? "faq--open" : ""}`}>
      <div className="faq__question" onClick={() => setIsOpen(!isOpen)}>
        <div className={`qstn ${classes}`} style={{ fontWeight: 400, marginRight: "20px" }}>
          {ReactHtmlParser(i18n.language === "en" ? question : question_in_hindi)}
        </div>
        <div className="arrow">
          <img src="/assets/img/Arrow.png" className="arrow-up" />
        </div>
      </div>
      <div className="faq__answer" style={{ fontSize: "18px", fontFamily: "Roboto" }}>
        <div className="content">
          {ReactHtmlParser(i18n.language === "en" ? answer : answer_in_hindi)}
          {bug && bug_options ? (
            <>
              <hr />

              {bug_options.option_1 && bug_options.option_1_in_hindi ? (
                <div class="form-check mb-3">
                  <label class="form-check-label">
                    <input
                      type="radio"
                      disabled={isSent || ures[fid] != undefined}
                      class="form-check-input"
                      name={bug_options.option_1}
                      checked={isAns === 1 || (ures[fid] != undefined && ures[fid] == 1) ? true : false}
                      onClick={() => setIsAns(1)}
                    />
                    {i18n.language === "en" ? bug_options.option_1 : bug_options.option_1_in_hindi}
                  </label>
                </div>
              ) : (
                ""
              )}

              {bug_options.option_2 && bug_options.option_2_in_hindi ? (
                <div class="form-check mb-3">
                  <label class="form-check-label">
                    <input
                      type="radio"
                      disabled={isSent || ures[fid] != undefined}
                      class="form-check-input"
                      name={bug_options.option_2}
                      checked={isAns === 2 || (ures[fid] != undefined && ures[fid] == 2) ? true : false}
                      onClick={() => setIsAns(2)}
                    />
                    {i18n.language === "en" ? bug_options.option_2 : bug_options.option_2_in_hindi}
                  </label>
                </div>
              ) : (
                ""
              )}

              {bug_options.option_3 && bug_options.option_3_in_hindi ? (
                <div class="form-check mb-3">
                  <label class="form-check-label">
                    <input
                      type="radio"
                      disabled={isSent || ures[fid] != undefined}
                      class="form-check-input"
                      name={bug_options.option_3}
                      checked={isAns === 3 || (ures[fid] != undefined && ures[fid] == 3) ? true : false}
                      onClick={() => setIsAns(3)}
                    />
                    {i18n.language === "en" ? bug_options.option_3 : bug_options.option_3_in_hindi}
                  </label>
                </div>
              ) : (
                ""
              )}

              {bug_options.option_4 && bug_options.option_4_in_hindi ? (
                <div class="form-check mb-3">
                  <label class="form-check-label">
                    <input
                      type="radio"
                      disabled={isSent || ures[fid] != undefined}
                      class="form-check-input"
                      name={bug_options.option_4}
                      checked={isAns === 4 || (ures[fid] != undefined && ures[fid] == 4) ? true : false}
                      onClick={() => setIsAns(4)}
                    />
                    {i18n.language === "en" ? bug_options.option_4 : bug_options.option_4_in_hindi}
                  </label>
                </div>
              ) : (
                ""
              )}

              {bug_options.option_5 && bug_options.option_5_in_hindi ? (
                <div class="form-check mb-3">
                  <label class="form-check-label">
                    <input
                      type="radio"
                      disabled={isSent || ures[fid] != undefined}
                      class="form-check-input"
                      name={bug_options.option_5}
                      checked={isAns === 5 ? true : false}
                      onClick={() => setIsAns(5)}
                    />
                    {i18n.language === "en" ? bug_options.option_5 : bug_options.option_5_in_hindi}
                  </label>
                </div>
              ) : (
                ""
              )}

              {isSent || ures[fid] != undefined ? (
                <>
                  <Text my={2} lineHeight="43px" fontFamily="heading" fontSize="23px" color="#9C1EE5">
                    {t("SUPPORTPAGE.THANK_YOU_FOR_SUBMITTING")}
                  </Text>
                </>
              ) : (
                <button
                  onClick={handleSubmit}
                  style={{ fontSize: "26px" }}
                  className={`see-all-btn ${isAns !== null ? "see-all-btn--active" : ""}`}>
                  {t("SUPPORTPAGE.SEND")}
                </button>
              )}
            </>
          ) : (
            ""
          )}
        </div>

        {bug && (image_1 || image_2) ? (
          <div className="imgs">
            {image_1 ? (
              <div className="img-block">
                <img src={image_1.url} />
                <p>{i18n.language === "en" ? image_1_caption : image_1_caption_in_hindi}</p>
              </div>
            ) : null}

            {image_2 ? (
              <div className="img-block">
                <img src={image_2.url} />
                <p>{i18n.language === "en" ? image_2_caption : image_2_caption_in_hindi}</p>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
}
