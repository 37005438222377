import React, { useState } from "react";
import { Flex, Box, Text, Link, Image } from "@chakra-ui/react";
import { AiOutlineClose } from "react-icons/ai";
import { useTranslation } from "react-i18next";

const AlertComponent = () => {
  const { t, i18n } = useTranslation();

  const [showAlert, setShowAlert] = useState(
    t("COMMON.SHOW_DOWNLOAD_ALERT") || false
  );

  return showAlert ? (
    <Flex align="center" p={1} justify="space-between" bg="black" color="white">
      <div></div>

      <Link isExternal href={t("COMMON.LINKS.PLAYSTORE")} px={4}>
        <Flex align="center" justify="center" mx={4} className="headline-txt">
          <Text
            fontFamily="palanquin_darkregular"
            mb={0}
            my="auto"
            mt={1.5}
          >
            {t("COMMON.SHOW_DOWNLOAD_ALERT_TEXT")}
          </Text>

          <Box px={2}></Box>

          <Image
            height="27px"
            src="/assets/img/GooglePlay.png"
            className="mt-1 mx-auto"
            alt=""
            srcSet=""
          />
        </Flex>
      </Link>

      <Flex
        align="center"
        justify="center"
        borderRadius="50%"
        width="20px"
        height="20px"
        bg="#696969"
        color="white"
        onClick={() => setShowAlert(false)}
        cursor="pointer"
      >
        <Box as={AiOutlineClose} color="white" />
      </Flex>
    </Flex>
  ) : null;
};

export default AlertComponent;
