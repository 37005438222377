import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { doFetchPartners } from "../../action/partners";
import SwiperCore, { Navigation } from "swiper";
import { Link } from "react-router-dom";
import { Box, Text, Link as CLink, Flex, Image } from "@chakra-ui/react";
import Slider from "react-slick";
import { ArrowSlide } from "./meet-memebers";

// install Virtual module
SwiperCore.use([Navigation]);

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1 ,
  className: "center",
  // centerMode: true,
  swipeToSlide: true,
  centerPadding: "0px",
  afterChange: (e) => {},
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 750,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
  nextArrow: <ArrowSlide type="next" color="gray.400" />,
  prevArrow: <ArrowSlide color="gray.400" />,
};

export default function Partners() {
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  
  const { t, i18n } = useTranslation();
  const partners = [];
  const teamsArray = [
    {
      imgURL: "OurTeamLogo_USAID.png",
      link: "https://www.usaid.gov/",
    },
    {
      imgURL: "OurTeamLogo_HDI.png",
      link: "https://www.howard-delafield.com/",
    },
    {
      imgURL: "OurTeamLogo_GoC.png",
      link: "https://www.gameofchoicenotchance.com/",
    },
    {
      imgURL: "OurTeamLogo_Metamersive.png",
      link: "https://metamersive.com/",
    },
    {
      imgURL: "OurTeamLogo_Vihara.png",
      link: "https://www.vihara.asia/",
    },
    {
      imgURL: "OurTeamLogo_CycleTech.png",
      link: "https://www.cycletechnologies.com/",
    },
    {
      imgURL: "OurTeamLogo_GirlEffect.png",
      link: "https://www.girleffect.org/",
    },
    // {
    //   imgURL: "OurTeamLogo_IG.png",
    //   link: "https://www.indusgeeks.com/",
    // },
    {
      imgURL: "OurTeamLogo_Jubi-Ai.png",
      link: "https://www.jubi.ai/",
    },
    {
      imgURL: "OurTeamLogo_MLLG.png",
      link: "https://www.mullenlowelintas.in/",
    },
    {
      imgURL: "OurTeamLogo_NamER.png",
    }
  ];

  return (
    <Box>
      <section className="partner" style={{ paddingBottom: "30px" }}>
        <div className="container carousel">
          <h3 className="partner__title">
            <Text
              fontFamily="heading"
              textAlign="center"
              fontSize="30px"
            >
              {t("HOMEPAGE.OUR_TEAM")}
            </Text>
          </h3>

          <Slider {...settings}>
            {teamsArray.map((team, index) => {
              return (
                <TeamBox
                  key={team?.imgURL}
                  virtualIndex={index}
                  thumbnail={`assets/img/team/${team?.imgURL}`}
                  external_url={team?.link}
                />
              );
            })}
          </Slider>
        </div>
      </section>
    </Box>
  );
}

const TeamBox = ({ thumbnail, external_url }) => {
  const { t, i18n } = useTranslation();

  return (
    <CLink
      display="block"
      isExternal
      cursor="pointer"
      href={external_url ? external_url : null}
      my="30px"
      className={`newsCard ${
        external_url ? "" : "hid-ptr"
      }`}
    >
      <Box
        width="200px"
        height="175px"
        borderRadius="30px"
        boxShadow="0 0 10px 8px rgb(0 0 0 / 10%)"
        position="relative"
        overflow="hidden"
        bg="white"
        mx="auto"
        borderWidth="1px"
        borderColor="gray.100"
      >
        <Flex
          width="100%"
          height="175px"
          align="center"
          justify="center"
          p={4}
          overflow="hidden"
        >
          <Image src={thumbnail} />
        </Flex>
      </Box>
    </CLink>
  );
};
