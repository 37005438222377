import SwiperCore, { Navigation } from "swiper";

import React from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import API from "../../api";
import { Text, Box } from "@chakra-ui/react";

import Slider from "react-slick";
import { ArrowSlide } from "./meet-memebers";

// install Swiper module
SwiperCore.use([Navigation]);

export const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  afterChange: (e) => {},
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
  nextArrow: <ArrowSlide type="next" color="gray.400" />,
  prevArrow: <ArrowSlide color="gray.400" />,
};

export default function Testimonials() {
  const { t, i18n } = useTranslation();
  let [testimonials, setTestimonials] = React.useState([]);
  let [isLoading, setIsLoading] = React.useState(true);

  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  React.useEffect(() => {
    fetchTestimonails();
  }, []);

  const fetchTestimonails = async () => {
    try {
      let data = await API.get("/testimonials");

      let t = data.data.map((testimonial) => {
        return { ...testimonial.acf };
      });

      setTestimonials(t);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  let stars = (rating) => {
    let starsArray = [];

    for (let index = 1; index <= 5; index++) {
      starsArray.push(".");
    }

    return starsArray.map((s, index) => {
      if (rating > index) {
        return (
          <img
            key={index}
            src="./assets/img/StarFull.png"
            className="img-fluid"
            alt=""
            srcSet=""
          />
        );
      }
      return (
        <img key={index} src="./assets/img/StarEmpty.png" alt="" srcSet="" />
      );
    });
  };

  if (testimonials.length === 0) {
    return "";
  }

  return (
    <section
      className="testimonial"
      style={{ paddingTop: "0px", paddingBottom: "40px" }}
    >
      <div
        className="container  carousel testimonial__carousel"
        style={{ paddingTop: "0px", paddingBottom: "0px" }}
      >
        <h3 className="testimonial__title">
          <Text
            fontFamily="heading"
            textAlign="center"
            fontSize="30px"
          >
            {t("HOMEPAGE.TESTIMONIAL.TITLE")}
          </Text>
        </h3>

        <Slider {...settings}>
          {testimonials.map((testimonial, index) => {
            return (
              <div key={index} className="item">
                <Box width="100%" maxWidth="280px" max="auto" className="testimonial">
                  <div className="stars">
                    {stars(parseInt(testimonial.rating))}
                  </div>

                  <Text
                    fontSize="18px"
                    fontFamily="body"
                    textAlign="left"
                    mt="20px"
                  >
                    {i18n.language === "en"
                      ? testimonial.testimony
                      : testimonial.testimony_in_hindi}
                  </Text>
                  <div className="name">
                    -{" "}
                    {i18n.language === "en"
                      ? testimonial.by
                      : testimonial.by_in_hindi}
                  </div>
                </Box>
              </div>
            );
          })}
        </Slider>
      </div>
    </section>
  );
}
