import React from "react";

import "font-awesome/css/font-awesome.min.css";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "./App.scss";

import Loading from "./components/Loading";
import Navigation from "./navigation";

import { ThemeProvider, CSSReset } from "@chakra-ui/react";
import theme from "./theme";

import { APP_VERSION } from "./constants";

function App() {
  console.log("Microsite v" + APP_VERSION);
  return (
    <React.Suspense fallback={<Loading />}>
      <ChakraWrapper>
        <Navigation />
      </ChakraWrapper>
    </React.Suspense>
  );
}

export default App;

const ChakraWrapper = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CSSReset />
      {children}
    </ThemeProvider>
  );
};
