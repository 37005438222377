export const BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost/microsite/wp/wp-json/wp/v2/";

export const API_BASE_URL = process.env.REACT_HDI_API_BASE_URL || "https://gameapi.gonishago.com/api";

export const APP_VERSION = process.env.REACT_APP_VERSION;

export const MY_HEALTH_SLUG = "my-health";
export const MY_CAREER_SLUG = "my-career";
export const RELATIONSHIP_SLUG = "relationships";
export const LIFESTYLE_SLUG = "lifestyles";

export const TRACKING_ID = "UA-214984811-1";
export const GA4_MEASUREMENT_ID = "G-9TKBD9Y8D9";
