import React from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollTop";

import Home from "./screens/Home";
import TopicCategory from "./screens/TopicCategory";
import Partners from "./screens/Partners";
import News from "./screens/News";
import TopicDetails from "./screens/TopicDetails";
import TopicDetailsWithoutCategory from "./screens/TopicDetailsWithoutCategory";
import PartnersDetails from "./screens/PartnersDetails";
import Search from "./screens/Search";
// import Support from "./screens/Support";
import Faqs from "./screens/Faqs";
import RateUs from "./screens/RateUs";
import AlertComponent from "./components/alert-component";
import GAComponent from "./components/ga-alert";
// import RouteChangeTracker from "./RouteChangeTracker";
import NotFound from "./screens/NotFound";

const Navigation = () => {
  return (
    <Router>
      {/* <RouteChangeTracker /> */}
      <ScrollToTop />
      <AlertComponent />
      <GAComponent />
      <Header />
      <Routes>
        <Route exact path="/my-channel" element={<News />} />
        <Route exact path="/news" element={<News />} />
        <Route exact path="/just4you/:partnerSlug" element={<PartnersDetails />} />
        <Route exact path="/just4you" element={<Partners />} />
        <Route exact path="/search" element={<Search />} />
        {/* <Route exact path="/support">
          <Support />
        </Route> */}
        <Route exact path="/faqs" element={<Faqs />} />
        <Route exact path="/rateus" element={<RateUs />} />
        <Route exact path="/topic/:topicSlug" element={<TopicDetailsWithoutCategory />} />
        <Route exact path="/:categorySlug/:topicSlug" element={<TopicDetails />} />
        <Route exact path="/:categorySlug" element={<TopicCategory />} />
        <Route exact path="/" element={<Home />} />
        <Route element={<NotFound />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default Navigation;
