import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Box, Text, Image, Flex } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export default function TopicCard({
  title,
  title_in_hindi,
  excerpt_in_english,
  excerpt_in_hindi,
  category,
  id,
  slug,
  thumbnail,
}) {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const goTo = (route) => {
    navigate(route);
  };

  return (
    <Flex
      onClick={() => goTo(category != "" ? `/${category}/${slug}` : `topic/${slug}`)}
      cursor="pointer"
      marginY="30px"
      mx="auto"
      className="topicCategoryCard"
      width="100%"
      maxWidth="300px"
      height="260px"
      position="relative"
      borderRadius="30px"
      boxShadow="lg"
      align="center"
      justify="center"
      boxShadow="0 0 20px 6px rgb(0 0 0 / 20%)">
      <Image src={thumbnail} m="auto" />

      <Box p={"20px"} pb={4} position="absolute" width="100%" left={0} bottom={0}>
        <Text mb={0} color="white" fontFamily="heading" fontSize="30px" lineHeight="1.3">
          {i18n.language == "en" ? title : title_in_hindi}
        </Text>
      </Box>
    </Flex>
  );
}
