export function formatCategories(unformatedCategoriesArray) {
  let categories = [];

  unformatedCategoriesArray.forEach((category) => {
    let cat = {
      id: category["id"] || "",
      name: category["name"] || "",
      name_in_hindi: category["name_in_hindi"] || "",
      description: category["description"] || "",
      description_in_hindi: category["description_in_hindi"] || "",
      video_playlist_in_english: category["video_playlist_in_english"] || "",
      video_playlist_in_hindi: category["video_playlist_in_hindi"] || "",
      slug: category["slug"],
      banner_img: category?.acf?.banner_image?.url || "",
    };

    categories.push(cat);
  });

  return categories;
}

export function formatTopics(unformatedTopicsArray) {
  let topics = [];

  unformatedTopicsArray.forEach((tpc) => {
    let topic = {
      id: tpc.id || "",
      title: tpc.title.rendered || "",
      title_in_hindi: tpc.title_in_hindi || "",
      english_video_link: tpc.acf.english_video_link || "",
      hindi_video_link: tpc.acf.hindi_video_link || "",
      excerpt_in_english: tpc.acf.excerpt_in_english || "",
      excerpt_in_hindi: tpc.acf.excerpt_in_hindi || "",
      body_content_in_english: tpc.acf.body_content_in_english || "",
      body_content_in_hindi: tpc.acf.body_content_in_hindi || "",
      slug: tpc.slug,
      categories: tpc.categories,
      thumbnail: tpc?.acf?.thumbnail?.url || "",
      show_on_homepage: tpc?.acf?.show_on_homepage || false,
    };

    topics.push(topic);
  });

  return topics;
}

export function formatPartners(unformatedPartnersArray) {
  let partners = [];
  unformatedPartnersArray.forEach((tpc) => {
    let partner = {
      id: tpc.id || "",
      title: tpc.title.rendered || "",
      slug: tpc.slug,
      categories: tpc.categories,
      excerpt_in_english: tpc.acf.excerpt_in_english,
      excerpt_in_hindi: tpc.acf.excerpt_in_hindi,
      partner_details_in_english: tpc.acf.partner_details_in_english,
      partner_details_in_hindi: tpc.acf.partner_details_in_hindi,
      partner_homepage_image: tpc.acf.partner_homepage_image.url,
      partner_title_in_hindi: tpc.acf.partner_title_in_hindi,
      partners_page_main_image: tpc.acf.partners_page_main_image,
      link: tpc.acf.link,
      ...tpc.acf,
    };

    partners.push(partner);
  });

  return partners;
}

export function formatNews(unformatedNewsArray) {
  let news = [];

  unformatedNewsArray.forEach((tpc) => {
    let nw = {
      id: tpc.id || "",
      title: tpc.title.rendered || "",
      news_title_in_hindi: tpc.acf.news_title_in_hindi || "",
      excerpt_in_english: tpc.acf.excerpt_in_english,
      excerpt_in_hindi: tpc.acf.excerpt_in_hindi,
      external_url: tpc.acf.external_url,
      thumbnail: tpc?.acf?.thumbnail?.url || "",
    };

    news.push(nw);
  });

  return news;
}

export function formatNumToInr(x) {
  x = x.toString();

  var afterPoint = "";

  if (x.indexOf(".") > 0) afterPoint = x.substring(x.indexOf("."), x.length);

  x = Math.floor(x);
  x = x.toString();

  var lastThree = x.substring(x.length - 3);

  var otherNumbers = x.substring(0, x.length - 3);

  if (otherNumbers != "") lastThree = "," + lastThree;

  return (
    otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint
  );
}
