import React from "react";
import { Image, Box } from "@chakra-ui/react";

export default function Banner({
  imgurl
}) {
  return (
    <section className="hero">
      <Image
        src={imgurl ? imgurl : "/assets/img/MiniBanner.png"}
        className="hero__banner"
        alt=""
        srcSet=""
        display={["none", "none", "block", "block"]}
      />

      <Box
        width="100%"
        height="200px"
        bg="gray.50"
        backgroundImage={imgurl ? imgurl : 'url("/assets/img/MiniBanner.png" )'}
        backgroundPosition="center center"
        backgroundSize="cover"
        display={["block", "block", "none", "none"]}
      ></Box>
    </section>
  );
}
