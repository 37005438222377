import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Link, Flex, Image, Text } from "@chakra-ui/react";
import { BiLinkExternal } from "react-icons/bi";
import openInNewTab from "../utils/OpenInNewTab";
import ReactHtmlParser from "react-html-parser";

export function NewsCard({ external_url, title, news_title_in_hindi, excerpt_in_english, excerpt_in_hindi }) {
  const { t, i18n } = useTranslation();

  return (
    <a class="curved__card" target="blank" onClick={(e) => openInNewTab(external_url)}>
      <div class="curved__card-header">
        <span>{ReactHtmlParser(i18n.language === "en" ? title : news_title_in_hindi)} </span>
      </div>
      <div class="curved__card-body">
        <span>{ReactHtmlParser(i18n.language === "en" ? excerpt_in_english : excerpt_in_hindi)} </span>
      </div>
    </a>
  );
}

const NewsCardWithImage = ({
  external_url,
  title,
  news_title_in_hindi,
  excerpt_in_english,
  excerpt_in_hindi,
  thumbnail,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <Link display="block" isExternal cursor="pointer" href={external_url} mx="auto" my="30px" className="newsCard">
      <Box
        width="300px"
        height="260px"
        borderRadius="30px"
        position="relative"
        overflow="hidden"
        bg="gray.50"
        boxShadow="0 0 20px 6px rgb(0 0 0 / 20%)">
        <Flex width="100%" height="170px" align="center" justify="center" p={4} overflow="hidden">
          <Image src={thumbnail} minWidth="300px" minHeight="170px" />

          <Box position="absolute" top="16px" right="16px">
            {/*<Box as={BiLinkExternal} />*/}
            <BiLinkExternal color="white" />
          </Box>
        </Flex>
        <Flex width="100%" height="90px" align="center" p={5} className="newsCard-text">
          <Text
            fontFamily="heading"
            color="white"
            fontSize="30px"
            textAlign="left"
            lineHeight="1.3"
            style={{
              textAlign: "left",
            }}>
            {/* {i18n.language == "en" ? props.title : props.partner_title_in_hindi} */}
            {ReactHtmlParser(i18n.language === "en" ? title : news_title_in_hindi)}
          </Text>
        </Flex>
      </Box>
    </Link>
  );
};

export default NewsCardWithImage;
