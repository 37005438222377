import React, { useState } from "react";
import { Flex, Text, Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { GA4_MEASUREMENT_ID } from "../constants";
import ReactGA from "react-ga4";

const GAComponent = () => {
  const { t, i18n } = useTranslation();

  const [showAlert, setShowAlert] = useState(true);

  const activateGA = () => {
    if (window.location.origin.indexOf("localhost") === -1) {
      ReactGA.initialize(GA4_MEASUREMENT_ID);
      ReactGA.set({ anonymizeIp: true });

      let path = window.location.pathname + window.location.search;
      let loc = window.location.origin + path;

      ReactGA.set({ page: path });
      ReactGA.send({ hitType: "pageview", page: loc });
    }
  };

  const changeGA = (v) => {
    setShowAlert(false);
    localStorage.setItem("gat", v);
    if (v === true) {
      activateGA();
    }
  };

  if (localStorage.getItem("gat") === "true") {
    activateGA();
  }

  return showAlert && localStorage.getItem("gat") === null ? (
    <div className="row" style={{ backgroundColor: "#000", color: "#FAFAFA", borderTop: "1px solid #FAFAFA" }}>
      <div className="col-12 text-center">
        <Flex align="center" justify="center" mx={4}>
          <Text fontFamily="palanquin_darkregular" mb={0} my="auto" fontSize="16px" mt={1.5}>
            {t("GA.TEXT")}
          </Text>
        </Flex>
      </div>
      <div className="col-12 text-center">
        <Flex align="center" justify="center" py="10px">
          <Button
            size="sm"
            colorScheme="white"
            border="2px solid"
            bg="white"
            borderRadius="16px"
            color="#e85900"
            className="whover analytics"
            px="20pt"
            onClick={(e) => {
              e.stopPropagation();
              changeGA(true);
            }}>
            <Text fontFamily="body" fontWeight="bold" fontSize="16px">
              {t("GA.A_BTN")}
            </Text>
          </Button>

          <Button
            size="sm"
            colorScheme="white"
            border="2px solid"
            bg="white"
            borderRadius="16px"
            color="#e85900"
            className="whover analytics"
            px="20pt"
            onClick={(e) => {
              e.stopPropagation();
              changeGA(false);
            }}>
            <Text fontFamily="body" fontWeight="bold" fontSize="16px">
              {t("GA.C_BTN")}
            </Text>
          </Button>
        </Flex>
      </div>
    </div>
  ) : null;
};

export default GAComponent;
