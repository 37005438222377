import React from "react";
import PartnerCard from "../components/PartnerCard";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../components/Loading";
import NotFound from "./NotFound";
import { useSelector, useDispatch } from "react-redux";
import { doFetchTopics, doFetchCategories } from "../action/topics";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import { Link } from "react-router-dom";
import { doFetchPartners } from "../action/partners";
import Banner from "../components/Banner";
import { Box, Text, Flex, Image } from "@chakra-ui/react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import RatingBox from "../components/RatingBox";
import { SetRateTopic } from "../api";

export default function TopicDetails() {
  const { t, i18n } = useTranslation();
  const { categorySlug, topicSlug } = useParams();

  const categories = useSelector((state) => state.topics.categories);
  const isCategoriesLoading = useSelector((state) => state.topics.isCategoriesLoading);
  const isCategoriesFetched = useSelector((state) => state.topics.isCategoriesFetched);
  const topics = useSelector((state) => state.topics.topics);
  const isTopicsLoading = useSelector((state) => state.topics.isTopicsLoading);
  const isTopicsFetched = useSelector((state) => state.topics.isTopicsFetched);
  const partners = useSelector((state) => state.topics.partners);

  var ures = {};
  if (sessionStorage.getItem("ures") !== null) {
    ures = JSON.parse(sessionStorage.getItem("ures"));
  }

  const dispatch = useDispatch();
  const topic = topics.find((tpc) => {
    return tpc.slug === topicSlug;
  });

  const category = categories.find((cat) => {
    return cat.slug == categorySlug;
  });

  React.useEffect(() => {
    doFetchPartners(dispatch);
  }, []);

  if (!topic || !category) {
    if (!isTopicsFetched) {
      doFetchTopics(dispatch);
    }

    if (!isCategoriesFetched) {
      doFetchCategories(dispatch);
    }
    if (!isTopicsFetched || !isCategoriesFetched) return <Loading />;
    return <NotFound />;
  }
  const filteredPartners = partners.filter((tpc) => tpc.categories.includes(category.id));

  // FINDING PREV AND NEXT TOPI
  const catTopics = topics.filter((tpc) => tpc.categories.includes(category.id));

  // console.log("CAT TOPICS", catTopics);

  const topicIndex = catTopics.findIndex((tpc) => {
    return tpc.slug === topicSlug;
  });
  let prevTopic, nextTopic;

  if (topicIndex != 0) {
    prevTopic = catTopics[topicIndex - 1];
  }

  if (topicIndex != catTopics.length + 1) {
    nextTopic = catTopics[topicIndex + 1];
    // console.log(nextTopic);
  }

  // const videoURL = `https://www.youtube.com/embed/${
  //   i18n.language == "en" ? topic.english_video_link : topic.hindi_video_link
  // }`;

  return (
    <div className="topicpage">
      <Banner imgurl={category.banner_img} />

      <div className="container">
        <div className="content">
          <div className="content__title">
            <Text pt="26px" fontFamily="heading" fontSize="30px" pb="16px">
              {i18n.language == "en" ? topic.title : topic.title_in_hindi}
            </Text>
          </div>
          <div className="content__para  linkContentBox">
            {ReactHtmlParser(i18n.language == "en" ? topic.body_content_in_english : topic.body_content_in_hindi)}
          </div>
        </div>

        <hr style={{ marginTop: "20px", marginBottom: "0px" }} />

        <Box maxWidth="550px" mx="auto">
          <RatingBox
            key={topic.id}
            question={
              <Text fontFamily="heading" textAlign="center" fontSize="xl">
                {t("TOPICS_PAGE.RATE_THIS_TOPIC")}
              </Text>
            }
            question_in_hindi={
              <Text fontFamily="heading" textAlign="center" fontSize="xl">
                {t("TOPICS_PAGE.RATE_THIS_TOPIC")}
              </Text>
            }
            noBorder={true}
            id={topic.id}
            url={SetRateTopic}
            ures={ures}
          />
        </Box>

        <hr style={{ marginTop: "0px", marginBottom: "60px" }} />
      </div>

      <Flex
        direction={["column", "column", "column", "row"]}
        align="center"
        justify="space-between"
        width="100%"
        maxWidth="1170px"
        mx="auto"
        mb="30px"
        px="20px">
        {prevTopic ? (
          <>
            <NavButton
              url={`/${category.slug}/${prevTopic.slug}`}
              title={i18n.language == "en" ? prevTopic.title : prevTopic.title_in_hindi}
              helperText={t("TOPICS_PAGE.PREV_TOPIC")}
              type="left"
            />
          </>
        ) : (
          <div className="pagination__item"> </div>
        )}

        <NavButton
          url={`/${category.slug}`}
          title={i18n.language == "en" ? category.name : category.name_in_hindi}
          helperText={t("TOPICS_PAGE.BACK_TO_CATEGORY")}
          type="center"
        />

        {nextTopic ? (
          <>
            <NavButton
              url={`/${category.slug}/${nextTopic.slug}`}
              title={i18n.language == "en" ? nextTopic.title : nextTopic.title_in_hindi}
              helperText={t("TOPICS_PAGE.Next_TOPIC")}
              type="right"
            />
          </>
        ) : (
          <div className="pagination__item"> </div>
        )}
      </Flex>
    </div>
  );
}

const NavButton = ({ url, type, title, helperText }) => {
  const navigate = useNavigate();

  const goTo = (url) => {
    navigate(url);
  };

  return (
    <Box marginBottom="30px">
      <Text
        px={8}
        fontSize="18px"
        fontFamily="body"
        marginBottom="4px"
        textAlign={type != "center" ? (type == "left" ? "right" : "left") : "center"}>
        {helperText}
      </Text>
      <Flex
        direction="row"
        align="center"
        width="290px"
        height="80px"
        borderRadius="52px"
        bg="#e85900"
        p={4}
        px="30px"
        color="white"
        onClick={() => goTo(url)}
        cursor="pointer"
        className="ohover">
        {type == "left" ? <Box fontSize="38px" as={BsChevronLeft} ml={"-8px"} /> : <> </>}

        <Text
          align={type == "center" ? "center" : type == "left" ? "right" : "left"}
          mx={type == "center" ? "auto" : 0}
          fontSize="20px"
          lineHeight="1.3"
          fontFamily="heading"
          width="100%">
          {title}
        </Text>

        {type == "right" ? <Box fontSize="38px" as={BsChevronRight} ml={2} /> : <> </>}
      </Flex>
    </Box>
  );
};
