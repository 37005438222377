import {
  FETCH_CATEGORIES,
  FETCH_CATEGORIES_COMPLETE,
  FETCH_TOPIC,
  FETCH_TOPIC_COMPLETE,
  FETCH_NEWS,
  FETCH_NEWS_COMPLETE,
  FETCH_PARTNERS,
  FETCH_PARTNERS_COMPLETE,
} from "../constants/action";

const initalState = {
  categories: [],
  isCategoriesLoading: false,
  istCategoriesFetched: false,
  topics: [],
  isTopicsLoading: false,
  isTopicsFetched: false,
  partners: [],
  isPartnersLoading: false,
  isPartnersFetched: false,
  news: [],
  isNewsLoading: false,
  isNewsFetched: false,
};

const topicsReducer = (state = initalState, action) => {
  switch (action.type) {
    case FETCH_CATEGORIES:
      return { ...state, isCategoriesLoading: true, isCategoriesFetched: false };

    case FETCH_CATEGORIES_COMPLETE:
      let categoriesArray = getUnique([...state.categories, ...action.payload.categories], "id");
      return { ...state, categories: categoriesArray, isCategoriesLoading: false, isCategoriesFetched: true };

    case FETCH_TOPIC:
      return { ...state, isTopicsLoading: true, isTopicsFetched: false };

    case FETCH_TOPIC_COMPLETE:
      let topicsArray = getUnique([...state.topics, ...action.payload.topics], "id");
      return { ...state, topics: topicsArray, isTopicsLoading: false, isTopicsFetched: true };

    case FETCH_PARTNERS:
      return { ...state, isPartnersLoading: true, isPartnersFetched: false };

    case FETCH_PARTNERS_COMPLETE:
      let partnersArray = getUnique([...state.partners, ...action.payload.partners], "id");
      return { ...state, partners: partnersArray, isPartnersLoading: false, isPartnersFetched: true };

    case FETCH_NEWS:
      return { ...state, isNewsLoading: true, isNewsFetched: false };

    case FETCH_NEWS_COMPLETE:
      let newsArray = getUnique([...state.news, ...action.payload.news], "id");
      return { ...state, news: newsArray, isNewsLoading: false, isNewsFetched: true };

    default:
      return state;
  }
};

function getUnique(array, key) {
  if (typeof key !== "function") {
    const property = key;
    key = function (item) {
      return item[property];
    };
  }
  return Array.from(
    array
      .reduce(function (map, item) {
        const k = key(item);
        if (!map.has(k)) map.set(k, item);
        return map;
      }, new Map())
      .values()
  );
}
export default topicsReducer;
