import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Banner from "../components/homepage/Banner";
import TopicCards from "../components/homepage/TopicCards";
import Headline from "../components/homepage/Headline";
import Testimonials from "../components/homepage/Testimonials";
import Metrics from "../components/homepage/Metrics";
import Partners from "../components/homepage/Partners";
import MeetMembers from "../components/homepage/meet-memebers";
import Just4You from "../components/homepage/Just4You";

import { Box } from "@chakra-ui/react";

export default function Home() {
  return (
    <div className="main homepage">
      <Banner />
      <TopicCards />
      <MeetMembers />
      <Metrics />
      <Testimonials />

      <Just4You />

      <Partners />
    </div>
  );
}
